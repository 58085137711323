<section id="password-login" class="flex flex-col items-center">
  <div class="flex flex-col pt-12 pb-8 px-4 container rounded border-stroke bg-primary">

    <div class="flex flex-col items-center">
      <div>
        <mat-icon *ngIf="isDarkEnable" svgIcon="ic_dark_quicko_logo_circle" class="h-[50px] w-[50px]"></mat-icon>
        <mat-icon *ngIf="!isDarkEnable" svgIcon="ic_quicko_logo_circle" class="h-[50px] w-[50px]"></mat-icon>
      </div>
      <p class="font-medium text-xl text-text-primary mt-2">Login into <span
          class="text-blue-900 font-semibold">Quicko</span></p>
      <p class="font-medium text-base text-center text-text-secondary mt-2">Please enter your password</p>
      <p class="font-semibold text-base text-blue-900" *ngIf="email">{{email}}</p>
      <p class="font-semibold text-base text-blue-900" *ngIf="mobile">+{{countryCode}} {{mobile}}</p>
    </div>

    <form [formGroup]="passwordForm" (keyup.enter)="verify()" class="mt-8">
      <mat-form-field appearance="outline" class="w-full">
        <mat-label [ngClass]="{'text-text-primary': isDarkEnable}">Password</mat-label>
        <input matInput formControlName="password" [type]="showPassword ? 'text' : 'password'" onpaste="return false"
          ondrop="return false" autocomplete="off" class="font-medium text-base text-text-primary">
        <mat-hint *ngIf="incorrectPassword" class="text-red-500 text-xs">Incorrect Password</mat-hint>
        <mat-hint
          *ngIf="passwordForm.controls['password'].errors && (passwordForm.controls['password'].dirty || passwordForm.controls['password'].touched) && passwordForm.controls['password'].errors['required']"
          class="text-red-500 text-xs">
          Required
        </mat-hint>
        <mat-icon matSuffix class="material-icons-outlined text-text-primary" (click)="showPassword=true"
          *ngIf="!showPassword">visibility</mat-icon>
        <mat-icon matSuffix class="material-icons-outlined text-text-primary" (click)="showPassword=false"
          *ngIf="showPassword">visibility_off</mat-icon>
      </mat-form-field>
    </form>

    <div class="flex flex-col items-start">
      <button (click)="navigateToForgotPassword()" class="font-medium text-base text-blue-900 mt-1">Forgot
        Password?</button>
      <button (click)="navigateToOTP()" class="font-medium text-sm text-blue-900 mt-6">Login with OTP</button>
    </div>
    <div class="flex flex-col gap-4 justify-center mt-24 mb-12">
      <ng-container *ngIf="!saveFormLoading; else loading">
        <button class="text-base font-medium text-white rounded bg-blue-900 w-full h-12" (click)="verify()">Log
          In</button>
      </ng-container>
      <button class="text-base font-medium text-blue-900 border rounded border-blue-900 w-full h-12"
        (click)="back()">Back</button>
    </div>

  </div>


  <ng-template #loading>
    <button class="text-base font-medium text-white rounded bg-blue-900 w-full h-12 flex justify-center items-center">
      <div class="loader" [ngStyle]="{'--loader-color': '#fff'}"></div>
    </button>
  </ng-template>
</section>