import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmailRecoveryComponent } from './email-recovery/email-recovery.component';
import { MobileRecoveryComponent } from './mobile-recovery/mobile-recovery.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-account-recovery',
  templateUrl: './account-recovery.component.html',
  standalone: true,
  imports: [EmailRecoveryComponent, MobileRecoveryComponent, NgIf]
})
export class AccountRecoveryComponent implements OnInit {
  @Input() useEmail: boolean = true;

  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.useEmail = (params['mobile'] && !params['email']) ? false : true;
    });
  }
  ngOnInit() { }
}
