import { NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-loader',
  templateUrl: './button-loader.component.html',
  styleUrls: ['./button-loader.component.css'],
  standalone: true,
  imports: [NgStyle, NgIf]
})
export class ButtonLoaderComponent implements OnInit {

  @Input() width?: string;
  @Input() height?: string;
  @Input() color?: string;
  @Input() type?: 'primary' | 'secondary';
  btnWidth: any;
  btnHeight: any;
  btnColor: any;

  constructor() { }

  ngOnInit() {
    this.btnWidth = this.width ?? '128';
    this.btnHeight = this.height ?? '48';
    this.btnColor = this.color ?? '#2962FF';
    this.type = this.type ?? 'primary';
  }

}
