<!-- <canvas riv="loading_bars_white" width={{btnWidth}} height={{btnHeight}}>
        <riv-player name="loading-1" play></riv-player>
    </canvas> -->
    <ng-container *ngIf="type === 'primary'; else secondary">
      <div
        [ngStyle]="{
          width: btnWidth + 'px',
          height: btnHeight + 'px',
          backgroundColor: btnColor
        }"
        class="flex items-center justify-center mat-rounded-5 gap-1"
      >
        <div class="dot dot_loader w-[10px] h-[10px]" style="color: white"></div>
        <div class="dot dot_loader w-[10px] h-[10px]" style="color: white"></div>
        <div class="dot dot_loader w-[10px] h-[10px]" style="color: white"></div>
      </div>
    </ng-container>
    <ng-template #secondary>
      <div
        [ngStyle]="{
              width: btnWidth + 'px',
              height: btnHeight + 'px',
              backgroundColor: '#ffffff',
            }"
        class="border flex items-center justify-center mat-rounded-5 gap-1"
      >
        <div
          class="dot dot_loader w-[10px] h-[10px]"
          [ngStyle]="{ color: btnColor }"
        ></div>
        <div
          class="dot dot_loader w-[10px] h-[10px]"
          [ngStyle]="{ color: btnColor }"
        ></div>
        <div
          class="dot dot_loader w-[10px] h-[10px]"
          [ngStyle]="{ color: btnColor }"
        ></div>
      </div>
    </ng-template>
    