<div class="flex flex-col pt-12 pb-8 px-4 rounded bg-primary gap-6 grow max-h-[100vh] min-h-full">

  <div class="flex flex-col gap-8 h-full">
    <div class="flex flex-col items-center gap-2">
      <div>
        <mat-icon *ngIf="isDarkEnable" svgIcon="ic_dark_quicko_logo_circle" class="h-[50px] w-[50px]"></mat-icon>
        <mat-icon *ngIf="!isDarkEnable" svgIcon="ic_quicko_logo_circle" class="h-[50px] w-[50px]"></mat-icon>
      </div>
      <div class="font-medium text-xl text-text-primary">Sign up at <span
          class="text-blue-900 font-semibold">Quicko</span></div>
      <div class="font-medium text-base text-center text-text-secondary">Join 2 Million happy taxpayers!</div>
    </div>
    <div class="flex flex-col gap-6">
      <form [formGroup]="userForm" (keyup.enter)="signUp()">
        <mat-form-field class="w-full" appearance="outline">
          <mat-label [ngClass]="{'text-text-primary':isDarkEnable}">Email</mat-label>
          <input matInput formControlName="email" placeholder="Email" class="font-medium text-base text-text-primary">
          <mat-hint *ngIf="isUser" class="font-medium text-xs text-alert-error-900">Account already
            registered.</mat-hint>
          <mat-hint *ngIf="googleError" class="font-medium text-xs text-alert-error-900">Failed to signin. Try
            again</mat-hint>
          <mat-hint *ngIf="userError" class="font-medium text-xs text-alert-error-900">{{userError}}</mat-hint>
          <mat-error
            *ngIf="userForm.controls['email'].errors && (userForm.controls['email'].dirty || userForm.controls['email'].touched)"
            class="font-medium text-xs text-alert-error-900">
            <mat-hint *ngIf="userForm.controls['email'].errors['required']">Required</mat-hint>
            <mat-hint *ngIf="userForm.controls['email'].errors['email']">Invalid email</mat-hint>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w-full" appearance="outline">
          <mat-label [ngClass]="{'text-text-primary':isDarkEnable}">First Name</mat-label>
          <input matInput formControlName="firstName" placeholder="First Name" class="font-medium text-text-primary">
          <mat-error
            *ngIf="userForm.controls['firstName'].errors && (userForm.controls['firstName'].dirty || userForm.controls['firstName'].touched)"
            class="font-medium text-xs text-alert-error-900">
            <mat-hint *ngIf="userForm.controls['firstName'].errors['required']">Required</mat-hint>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w-full" appearance="outline">
          <mat-label [ngClass]="{'text-text-primary':isDarkEnable}">Last Name</mat-label>
          <input matInput formControlName="lastName" placeholder="Last Name"
            class="font-medium text-base text-text-primary">
          <mat-error
            *ngIf="userForm.controls['lastName'].errors && (userForm.controls['lastName'].dirty || userForm.controls['lastName'].touched)"
            class="font-medium text-xs text-alert-error-900">
            <mat-hint *ngIf="userForm.controls['lastName'].errors['required']">Required</mat-hint>
          </mat-error>
        </mat-form-field>
      </form>
      <div class="flex flex-col gap-6">
        <div class="flex flex-col gap-4 justify-center">
          <ng-container #blueButton *ngIf="!signUploading; else loading">
            <button class="text-base font-medium text-white rounded bg-blue-900 w-full h-12" (click)="signUp()">
              Sign up
            </button>
          </ng-container>
          <ng-container *ngIf="!googleLoading; else blueLoading">
            <asl-google-signin-button type='standard' size='large' [width]="googleButtonWidth" text="continue_with"
              logo_alignment="center"></asl-google-signin-button>
          </ng-container>
        </div>

        <p class="font-medium text-sm mt-6 text-center text-blue-grey-400">By signing up, you agree to our <a
            href="https://help.quicko.com/support/solutions/articles/84000350456-terms-conditions-of-use"
            target="_blank" class="underline">Terms of Service.</a></p>
      </div>
    </div>
  </div>
  <p class="font-medium text-base text-text-secondary mt-6 text-center">Already have an account? <a
      routerLink="../login" class="text-blue-900 underline">Sign
      in</a></p>

</div>



<ng-template #loading>
  <button class="text-base font-medium text-white rounded bg-blue-900 w-full h-12 flex justify-center items-center">
    <div class="loader" [ngStyle]="{'--loader-color': '#fff'}"></div>
  </button>
</ng-template>
<ng-template #blueLoading>
  <button
    class="flex text-base font-medium text-blue-900 border rounded border-blue-900 w-full h-12 justify-center items-center">
    <div class="loader" [ngStyle]="{'--loader-color': '#2962FF'}"></div>
  </button>
</ng-template>