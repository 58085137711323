import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { EntitlementsService } from '../../services/entitlements.service';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { NgIf } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  standalone: true,
  imports: [MatIconModule, MatDividerModule, NgIf, RouterModule]
})
export class LogoutComponent implements OnInit {

  isDarkEnable: boolean = false;
  email: string | null;

  constructor(private entitlementsService: EntitlementsService, private authService: AuthService) {
    this.email = this.authService.getEmail();
  }

  ngOnInit() {

    this.entitlementsService.logout();
    this.authService.deleteToken();

    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      if (cookie.split('=')[0].trim() == 'theme') {
        if (cookie.split('=')[1].trim() == 'dark') {
          this.isDarkEnable = true;
        } else {
          this.isDarkEnable = false;
        }
        break;
      }
    }
  }
}
