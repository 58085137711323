import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AffiliateService } from './affiliate.service';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EntitlementsService {
  private endpoint;

  constructor(
    private httpClient: HttpClient,
    private affiliateService: AffiliateService
  ) {
    this.endpoint = environment.auth_services_endpoint + '/auth/user';
  }

  getUserMetadata(email: string) {
    const url = this.endpoint + '/meta';
    var user = {
      '@entity': 'org.quicko.auth.user',
      username: email,
    };
    return this.httpClient.post(url, user);
  }

  getUserMetadataByMobile(countryCode: string, mobile: string) {
    const url = this.endpoint + '/meta';
    var user = {
      '@entity': 'org.quicko.auth.user',
      country_code: countryCode,
      mobile: mobile
    };
    return this.httpClient.post(url, user);
  }

  refresh(token: string) {
    const url = this.endpoint + '/authorize';
    return this.httpClient.post<any>(url, null, {
      params: new HttpParams().set('request_token', token)
    });
  }

  login(email: string, password: string) {
    var user = {
      '@entity': 'org.quicko.auth.user',
      username: email,
      password: password,
    };

    const url = this.endpoint + '/login';
    return this.httpClient.post(url, user);
  }

  logout() {
    const url = this.endpoint + '/logout';
    return this.httpClient.post(url, null);
  }

  loginByMobile(countryCode: string, mobile: string, password: string) {
    var user = {
      '@entity': 'org.quicko.auth.user',
      mobile: mobile,
      country_code: countryCode,
      password: password,
    };

    const url = this.endpoint + '/login';
    return this.httpClient.post(url, user);
  }

  getOTPByEmail(email: string) {
    const url = this.endpoint + '/login/otp/generate';
    var user = {
      '@entity': 'org.quicko.auth.user',
      username: email,
    };
    return this.httpClient.post(url, user);
  }

  verifyEmailOTP(email: string, otp: string) {
    let params: HttpParams = new HttpParams().set('otp', otp);
    var user = {
      '@entity': 'org.quicko.auth.user',
      username: email,
    };
    // params = this.affiliateService.getAffiliateParams(params);
    const url = this.endpoint + '/login/otp/verify';
    return this.httpClient.post(url, user, {
      params: new HttpParams().set('otp', otp),
    });
  }

  getOTPByMobile(mobile: string, countryCode: string) {
    const url = this.endpoint + '/login/otp/generate';
    var user = {
      '@entity': 'org.quicko.auth.user',
      mobile: mobile,
      country_code: countryCode,
    };
    return this.httpClient.post(url, user);
  }

  verifyMobileOTP(mobile: string, countryCode: string, otp: string) {
    const url = this.endpoint + '/login/otp/verify';
    var user = {
      '@entity': 'org.quicko.auth.user',
      mobile: mobile,
      country_code: countryCode,
    };
    return this.httpClient.post(url, user, {
      params: new HttpParams().set('otp', otp),
    });
  }

  getOTPByNewMobile(email: string, mobile: string, countryCode: string) {
    const url = this.endpoint + '/mobile';
    var user = {
      '@entity': 'org.quicko.auth.user',
      username: email,
      mobile: mobile,
      country_code: countryCode,
    };
    return this.httpClient.post(url, user);
  }

  verifyNewMobileOTP(
    email: string,
    mobile: string,
    countryCode: string,
    otp: string
  ) {
    const url = this.endpoint + '/mobile/otp/verify';
    var user = {
      '@entity': 'org.quicko.auth.user',
      username: email,
      mobile: mobile,
      country_code: countryCode,
    };
    return this.httpClient.post(url, user, {
      params: new HttpParams().set('otp', otp),
    });
  }

  signup(email: string, firstName?: string, lastName?: string) {
    var userDetails = {
      '@entity': 'org.quicko.auth.user.user_details',
      username: email,
      first_name: firstName,
      last_name: lastName,
    };

    const url = this.endpoint + '/signup';
    return this.httpClient.post(url, userDetails);
  }

  signupOTPVerify(email: string, otp: string) {
    let params: HttpParams = new HttpParams().set('otp', otp);
    var user = {
      '@entity': 'org.quicko.auth.user',
      username: email,
    };
    params = this.affiliateService.getAffiliateParams(params);

    const url = this.endpoint + '/signup/otp/verify';
    return this.httpClient.post(url, user, {
      params: params,
    });
  }

  setPassword(password: string) {
    var user = {
      '@entity': 'org.quicko.auth.user',
      password: password,
    };

    const url = this.endpoint + '/password';
    return this.httpClient.post(url, user);
  }

  resetPasswordEmail(email: string) {
    var user = {
      '@entity': 'org.quicko.auth.user',
      username: email
    };

    const url = this.endpoint + '/reset-password';
    return this.httpClient.post(url, user);
  }

  resetPasswordMobile(mobile: string, countryCode: string) {
    var user = {
      '@entity': 'org.quicko.auth.user',
      mobile: mobile,
      country_code: countryCode
    };

    const url = this.endpoint + '/reset-password';
    return this.httpClient.post(url, user);
  }

  verifyResetPasswordEmail(email: string, otp: string) {
    var user = {
      '@entity': 'org.quicko.auth.user',
      username: email
    };

    const url = this.endpoint + '/reset-password/otp/verify';
    return this.httpClient.post(url, user, {
      params: new HttpParams().set('otp', otp),
    });
  }

  verifyResetPasswordMobile(mobile: string, countryCode: string, otp: string) {
    var user = {
      '@entity': 'org.quicko.auth.user',
      mobile: mobile,
      country_code: countryCode
    };

    const url = this.endpoint + '/reset-password/otp/verify';
    return this.httpClient.post(url, user, {
      params: new HttpParams().set('otp', otp),
    });
  }
}
