import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { MatError, MatFormField, MatHint, MatInputModule, MatLabel } from '@angular/material/input';
import { AuthService } from '../../../services/auth.service';
import { EntitlementsService } from '../../../services/entitlements.service';
import { SharedService } from '../../../services/shared.service';
import { NgClass, NgForOf, NgIf, NgStyle } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SocialLoginModule } from '@abacritt/angularx-social-login';
import { NgOtpInputModule } from 'ng-otp-input';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-otp-login',
  templateUrl: './otp-login.component.html',
  styleUrls: ['./otp-login.component.css'],
  standalone: true,
  imports: [MatFormField, MatLabel, ReactiveFormsModule, NgClass, MatHint, MatError, MatIcon, NgIf, SocialLoginModule, MatInputModule, NgOtpInputModule, NgStyle]
})
export class OtpLoginComponent implements OnInit {
  email: string | undefined;
  mobile: string | undefined;
  countryCode: string | undefined;
  credential: string | undefined;
  otp: string | undefined;
  timeLeft = 30;
  interval: any;
  isDarkEnable: boolean = false;
  redirectUrl: string | undefined;
  saveFormLoading: boolean = false;
  errorMessage: string | undefined;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private entitlementsService: EntitlementsService,
    private authService: AuthService,
    private sharedService: SharedService
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.email = params['email'];
      this.mobile = params['mobile'];
      this.countryCode = params['countryCode'];
      this.credential = params['credential'];
      if (!this.email && (!this.mobile || !this.countryCode)) {
        this.router.navigate(['../']);
      }
    });

    this.sharedService.getRedirectUrl().subscribe((redirectUrl: any) => {
      this.redirectUrl = redirectUrl;
    })
  }

  ngOnInit() {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      if (cookie.split('=')[0].trim() == 'theme') {
        if (cookie.split('=')[1].trim() == 'dark') {
          this.isDarkEnable = true;
        } else {
          this.isDarkEnable = false;
        }
        break;
      }
    }
  }

  verify() {
    if (this.email && this.otp) {
      this.saveFormLoading = true;
      this.entitlementsService.verifyEmailOTP(this.email, this.otp).subscribe({
        next: (response: any) => {
          this.authService.setToken(response['access_token'])

          if (this.redirectUrl) {
            window.location.href = window.atob(this.redirectUrl);
          }
          else {
            window.location.href = environment.income_tax_url;
          }
          this.saveFormLoading = false;
        },
        error: (err) => {
          if (err.error.message.includes('Expired OTP') || err.error.message.includes('Invalid OTP')) {
            this.errorMessage = 'Please enter a valid OTP';
          }
          else {
            this.router.navigate(['login']);
          }
          this.saveFormLoading = false;
        },
      });
    } else if (this.otp && this.mobile && this.countryCode) {
      this.saveFormLoading = true;
      this.entitlementsService
        .verifyMobileOTP(this.mobile, this.countryCode, this.otp)
        .subscribe({
          next: (response: any) => {
            this.authService.setToken(response['access_token'])

            if (this.redirectUrl) {
              window.location.href = window.atob(this.redirectUrl);
            }
            else {
              window.location.href = environment.income_tax_url;
            }
            this.saveFormLoading = false;
          },
          error: (err) => {
            if (err.error.message.includes('Expired OTP') || err.error.message.includes('Invalid OTP')) {
              this.errorMessage = err.error.message;
            }
            else {
              this.router.navigate(['login']);
            }
            this.saveFormLoading = false;
          },
        });
    } else {
      this.errorMessage = 'Provide valid OTP';
    }
  }

  generateOtp() {
    if (this.email) {
      this.entitlementsService.getOTPByEmail(this.email);
    } else if (this.mobile && this.countryCode) {
      this.entitlementsService
        .getOTPByMobile(this.mobile, this.countryCode);
    }
  }

  onOtpChange(otp: string) {
    this.errorMessage = undefined;
    if (otp.length === 6) {
      this.otp = otp;
    }
  }

  resendOTP() {
    this.generateOtp();
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
        this.timeLeft = 30;
      }
    }, 1000);
  }

  navigateToPassword() {
    this.router.navigate(['../password'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        email: this.email,
        credential: this.credential,
        mobile: this.mobile,
        countryCode: this.countryCode,
      },
    });
  }

  back() {
    this.router.navigate(['../../'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        email: this.email,
        mobile: this.mobile,
        countryCode: this.countryCode,
      },
    });
  }
}
