import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { EmailLoginComponent } from '../login/email-login/email-login.component';
import { MobileLoginComponent } from '../login/mobile-login/mobile-login.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-mobile-app-login',
  templateUrl: './mobile-app-login.component.html',
  standalone: true,
  imports: [EmailLoginComponent, MobileLoginComponent, NgIf]
})
export class MobileAppLoginComponent implements OnInit {

  @Input() useEmail: boolean = true;

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.useEmail = ((params['email'] || params['email'] === undefined) && !params['mobile']) ? true : false;
    });
  }

}
