import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmailLoginComponent } from './email-login/email-login.component';
import { MobileLoginComponent } from './mobile-login/mobile-login.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  standalone: true,
  imports: [EmailLoginComponent, MobileLoginComponent, NgIf]
})
export class LoginComponent implements OnInit {
  @Input() useEmail: boolean = true;

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.useEmail = ((params['email'] || params['email'] === undefined) && !params['mobile']) ? true : false;
    });
  }
}
