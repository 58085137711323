import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { SessionCookieService } from './session-cookie.service';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class AffiliateService {
  constructor(private cookieService: CookieService, private sessionCookieService: SessionCookieService) {
  }

  public setCookie(affiliateId: string): void {
    if (!this.cookieService.get('AFFILIATEID')) {
      if (environment.production) {
        this.cookieService.set('AFFILIATEID', affiliateId, 30, '/', '.quicko.com', true, 'Lax');
      }
      else {
        this.cookieService.set('AFFILIATEID', affiliateId, 30, '/', 'localhost', true, 'Lax');
      }
    }
  }

  public getCookie(): string {
    return this.cookieService.get('AFFILIATEID');
  }

  public getAffiliateParams(params?: HttpParams) {
    if (params == null) {
      params = new HttpParams;
    }

    if (this.getCookie()) {
      params = params.append('affiliate_id', this.getCookie());
    }

    if (this.sessionCookieService.getCookie('UTM_SOURCE')) {
      params = params.append('utm_source', this.sessionCookieService.getCookie('UTM_SOURCE'));
    }

    if (this.sessionCookieService.getCookie('UTM_MEDIUM')) {
      params = params.append('utm_medium', this.sessionCookieService.getCookie('UTM_MEDIUM'));
    }

    if (this.sessionCookieService.getCookie('UTM_CAMPAIGN')) {
      params = params.append('utm_campaign', this.sessionCookieService.getCookie('UTM_CAMPAIGN'));
    }

    if (this.sessionCookieService.getCookie('UTM_ID')) {
      params = params.append('utm_id', this.sessionCookieService.getCookie('UTM_ID'));
    }

    if (this.sessionCookieService.getCookie('UTM_TERM')) {
      params = params.append('utm_term', this.sessionCookieService.getCookie('UTM_TERM'));
    }

    if (this.sessionCookieService.getCookie('UTM_CONTENT')) {
      params = params.append('utm_content', this.sessionCookieService.getCookie('UTM_CONTENT'));
    }

    return params;
  }

}
