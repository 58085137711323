<section *ngIf="apps; else loading" id="email-login" class="flex flex-col items-center">
  <div class="flex flex-col pt-12 pb-8 container rounded border-stroke bg-primary">
    <div class="px-4">
      <div class="flex flex-col items-center gap-2">
        <div>
          <mat-icon *ngIf="isDarkEnable" svgIcon="ic_dark_quicko_logo_circle"
            class="h-[50px] w-[50px] absolute"></mat-icon>
          <mat-icon *ngIf="!isDarkEnable" svgIcon="ic_quicko_logo_circle" class="h-[50px] w-[50px] absolute"></mat-icon>
          <div
            class="h-[50px] w-[50px] relative ml-9 border-[3px] rounded-[50%] border-stroke-icon bg-primary flex items-center justify-center">
            <img src="{{app['icon']}}" class="h-[30px] w-[30px] rounded-[50%]" />
          </div>
        </div>
        <div class="font-medium text-xl text-text-primary">Logging you into <span
            class="text-blue-900 font-semibold">Quicko</span></div>
        <div class="font-medium text-base text-center text-text-secondary">We love our <span>{{app['name']}}</span>
          users!
        </div>
      </div>

      <p class="font-semibold text-base text-text-primary mt-10">Here’s what we access</p>


      <p class="font-medium text-base text-text-secondary mt-4">Your basic profile details</p>
      <p class="font-medium text-base text-text-secondary mt-4">Your communication details</p>

      <p class="font-semibold text-base text-text-primary mt-8">Here’s what we do</p>

      <p class="font-medium text-base text-text-secondary mt-4">Setting up your profile on Quicko</p>

    </div>
    <div class="border-t border-stroke mt-32 bg-grey-bg-100">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      <p class="font-medium text-sm text-text-secondary text-center mt-8">Please wait while we connect. Do not press
        back, refresh, or
        close this page</p>
    </div>
  </div>


</section>

<ng-template #loading>
  <div class="flex justify-center items-center h-screen">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
</ng-template>