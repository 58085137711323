import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { MatError, MatFormField, MatHint, MatInputModule, MatLabel } from '@angular/material/input';
import { SocialLoginModule } from '@abacritt/angularx-social-login';
import { EntitlementsService } from '../../../services/entitlements.service';
import { AuthService } from '../../../services/auth.service';
import { SharedService } from '../../../services/shared.service';
import { NgClass, NgIf, NgStyle } from '@angular/common';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-password-login',
  templateUrl: './password-login.component.html',
  standalone: true,
  imports: [MatFormField, MatLabel, ReactiveFormsModule, NgClass, MatHint, MatError, MatIcon, NgIf, SocialLoginModule, MatInputModule, NgStyle]
})
export class PasswordLoginComponent implements OnInit {
  email!: string;
  mobile: string | undefined;
  countryCode: string | undefined;
  credential!: string;
  passwordForm: FormGroup;
  showPassword: boolean = false;
  isDarkEnable: boolean = false;
  redirectUrl: string | undefined;
  saveFormLoading: boolean = false;
  incorrectPassword: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private entitlementsService: EntitlementsService,
    private authService: AuthService,
    private sharedService: SharedService,
  ) {
    this.passwordForm = new FormGroup({
      password: new FormControl('', [Validators.required]),
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      this.email = params['email'];
      this.mobile = params['mobile'];
      this.credential = params['credential'];
      this.countryCode = params['countryCode'];
    });

    this.sharedService.getRedirectUrl().subscribe((redirectUrl) => {
      this.redirectUrl = redirectUrl;
    })


  }

  ngOnInit() {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      if (cookie.split('=')[0].trim() == 'theme') {
        if (cookie.split('=')[1].trim() == 'dark') {
          this.isDarkEnable = true;
        } else {
          this.isDarkEnable = false;
        }
        break;
      }
    }

    this.passwordForm.controls['password'].valueChanges.subscribe(() => {
      this.incorrectPassword = false;
    });
  }

  verify() {
    if (this.passwordForm.valid && this.email) {
      this.saveFormLoading = true;
      this.entitlementsService
        .login(this.email, this.passwordForm.controls['password'].value)
        .subscribe({
          next: (response: any) => {
            this.authService.setToken(response['access_token'])

            if (this.redirectUrl) {
              window.location.href = window.atob(this.redirectUrl);
            }
            else {
              window.location.href = environment.income_tax_url;
            }
            this.saveFormLoading = false;
          },
          error: (err) => {
            if (err.error.message) {
              if (err.error.message.includes('Bad credentials')) {
                this.incorrectPassword = true;
              }
              else {
                this.router.navigate(['login']);
              }
            };
            this.saveFormLoading = false;
          },
        });
    } else if (this.passwordForm.valid && this.mobile && this.countryCode) {
      this.entitlementsService
        .loginByMobile(this.countryCode, this.mobile, this.passwordForm.controls['password'].value)
        .subscribe({
          next: (response: any) => {
            this.authService.setToken(response['access_token'])

            if (this.redirectUrl) {
              window.location.href = window.atob(this.redirectUrl);
            }
            else {
              window.location.href = environment.income_tax_url;
            }
            this.saveFormLoading = false;
          },
        });
    }
    else {
      this.passwordForm.controls['password'].markAsTouched();
    }
  }

  navigateToOTP() {
    this.router.navigate(['../otp'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        email: this.email,
        credential: this.credential,
        mobile: this.mobile,
        countryCode: this.countryCode,
      },
    });
  }

  navigateToForgotPassword() {
    this.router.navigate(['/set-password/verify'], {
      queryParams: {
        email: this.email,
        mobile: this.mobile,
        countryCode: this.countryCode,
      },
    });
  }

  back() {
    this.router.navigate(['../../'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        email: this.email,
        mobile: this.mobile,
        countryCode: this.countryCode,
      },
    });
  }
}
