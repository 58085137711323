import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ReferenceDataService } from '../../../../services/reference-data.service';
import { EntitlementsService } from '../../../../services/entitlements.service';
import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { MatFormField } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-mobile-recovery',
  templateUrl: './mobile-recovery.component.html',
  standalone: true,
  imports: [MatIconModule, ReactiveFormsModule, MatFormField, MatInputModule, NgClass, NgFor, NgIf, MatOptionModule, MatSelectModule, RouterModule, NgStyle, MatProgressSpinnerModule]
})
export class MobileRecoveryComponent implements OnInit {
  mobileForm: FormGroup;
  countryCode: number = 91;
  countryList: any;
  selectedCountry: any;
  isDarkEnable: boolean = false;
  isUser: boolean = true;
  saveFormLoading: boolean = false;

  @Output() changeLoginTypeEmitter: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private router: Router,
    private referenceDataService: ReferenceDataService,
    private entitlementsService: EntitlementsService,
    private activatedRoute: ActivatedRoute
  ) {
    this.mobileForm = new FormGroup({
      mobile: new FormControl('', [Validators.required]),
    });
    this.activatedRoute.queryParams.subscribe((params) => {
      this.mobileForm.controls['mobile'].setValue(params['mobile']);
      if (params['countryCode']) {
        this.countryCode = parseInt(params['countryCode']);
      }
    });
  }

  ngOnInit() {
    this.referenceDataService.getCountries().subscribe((response: any) => {
      this.countryList = response['data'];
      this.selectedCountry = this.countryList.find(
        (c: any) => c.numericCode === this.countryCode
      );
      if (!this.selectedCountry) {
        this.selectedCountry = this.countryList.find(
          (c: any) => c.numericCode === 91
        );
      }
    });
    this.mobileForm.controls['mobile'].valueChanges.subscribe(() => {
      this.isUser = true;
    });
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      if (cookie.split('=')[0].trim() == 'theme') {
        if (cookie.split('=')[1].trim() == 'dark') {
          this.isDarkEnable = true;
        } else {
          this.isDarkEnable = false;
        }
        break;
      }
    }
  }

  save() {
    if (this.mobileForm.valid && this.selectedCountry) {
      this.saveFormLoading = true;
      this.entitlementsService
        .getUserMetadataByMobile(this.selectedCountry.numericCode.toString(),
          this.mobileForm.controls['mobile'].value,)
        .subscribe({
          next: (response: any) => {
            this.entitlementsService
              .resetPasswordMobile(
                this.mobileForm.controls['mobile'].value,
                this.selectedCountry.numericCode
              )
              .subscribe(() => {
                this.router.navigate(['otp'], {
                  relativeTo: this.activatedRoute,
                  queryParams: {
                    mobile: this.mobileForm.controls['mobile'].value,
                    countryCode: this.selectedCountry.numericCode,
                  },
                });
                this.saveFormLoading = false;
              })
          },
          error: (err) => {
            if (err.error.message.includes('User does not exist')) {
              this.isUser = false;
            }
            this.saveFormLoading = false;
          },
        })
    } else {
      this.mobileForm.controls['mobile'].markAsTouched();
    }
  }

  onChangeLoginType() {
    this.changeLoginTypeEmitter.emit(true);
  }
}
