import { Component, OnInit } from '@angular/core';
import { EntitlementsService } from '../../services/entitlements.service';
import { AuthService } from '../../services/auth.service';
import { SharedService } from '../../services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-reauthenticate',
  templateUrl: './reauthenticate.component.html',
})
export class ReAuthenticateComponent implements OnInit {

  redirectUrl: string | undefined;

  constructor(private entitlementsService: EntitlementsService, private authService: AuthService, private sharedService: SharedService, private router: Router, private activatedRoute: ActivatedRoute) { }
  // TODO: remove session cookie, retain "redirect" query param, navigate to login with "redirect" param

  ngOnInit() {
    this.entitlementsService.logout();
    this.authService.deleteToken();

    this.sharedService.getRedirectUrl().subscribe((redirectUrl) => {
      this.redirectUrl = redirectUrl;
    });

    this.router.navigate(['login'], {
      queryParams: {
        redirect: this.redirectUrl
      },
    });
  }

}
