import { AfterContentInit, AfterViewInit, Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatFormField, MatLabel, MatHint, MatError } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { GoogleSigninButtonModule, SocialAuthService, SocialLoginModule } from '@abacritt/angularx-social-login';
import { EntitlementsService } from '../../../services/entitlements.service';
import { SharedService } from '../../../services/shared.service';
import { OAuthService } from '../../../services/oauth.service';
import { AuthService } from '../../../services/auth.service';
import { NgClass, NgIf, NgStyle } from '@angular/common';
import { ButtonLoaderComponent } from '../../common/button-loader/button-loader.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-email-login',
  templateUrl: './email-login.component.html',
  styleUrls: ['./email-login.component.css'],
  standalone: true,
  imports: [MatFormField, MatLabel, ReactiveFormsModule, NgClass, MatHint, MatError, MatIcon, NgIf, SocialLoginModule, MatInputModule, RouterModule, ButtonLoaderComponent, NgStyle, GoogleSigninButtonModule]
})

export class EmailLoginComponent implements OnInit, AfterViewInit {
  userForm: FormGroup;
  isUser: boolean = true;
  isDarkEnable: boolean = false;
  googleError: boolean = false;
  redirectUrl: string | undefined;
  saveFormLoading: boolean = false;
  googleLogin: boolean = false;
  userError: string | undefined;
  googleButtonWidth: number;

  @Output() changeLoginTypeEmitter: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('blueButton')
  blueButton!: ElementRef<HTMLButtonElement>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private entitlementsService: EntitlementsService,
    private socialAuthService: SocialAuthService,
    private authService: AuthService,
    private oauthService: OAuthService,
    private sharedService: SharedService
  ) {

    this.googleButtonWidth = 326;

    this.userForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      this.userForm.controls['email'].setValue(params['email']);
    });
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      if (cookie.split('=')[0].trim() == 'theme') {
        if (cookie.split('=')[1].trim() == 'dark') {
          this.isDarkEnable = true;
        } else {
          this.isDarkEnable = false;
        }
        break;
      }
    }

    this.sharedService.getRedirectUrl().subscribe((redirectUrl) => {
      this.redirectUrl = redirectUrl;
    })
  }


  ngAfterViewInit(): void {
    this.googleButtonWidth = this.blueButton?.nativeElement?.clientWidth!
  }

  ngOnInit() {
    this.socialAuthService.authState.subscribe((user) => {
      if (user) {
        this.saveFormLoading = true;
        this.oauthService
          .authorizeGoogle(
            user['idToken'],
            user['email'],
            user['firstName'] ? user['firstName'] : null,
            user['lastName'] ? user['lastName'] : null
          )
          .subscribe({
            next: (response: any) => {
              this.authService.setToken(response['access_token'])

              if (this.redirectUrl) {
                window.location.href = window.atob(this.redirectUrl);
              }
              else {
                window.location.href = environment.income_tax_url;
              };
              this.googleLogin = false;
              this.saveFormLoading = false;
            },
            error: (err) => {
              if (err.error.message.includes('User is locked')) {
                this.router.navigate(['./locked']);
              }
              this.saveFormLoading = false;
              this.googleError = true;
              this.googleLogin = false;
            }
          });
      }
    })


    this.userForm.controls['email'].valueChanges.subscribe(() => {
      this.isUser = true;
      this.googleError = false;
      this.userError = undefined;
    });
  }

  save() {
    if (this.userForm.valid) {
      this.saveFormLoading = true;
      this.entitlementsService
        .getUserMetadata(this.userForm.controls['email'].value)
        .subscribe({
          next: (response: any) => {
            this.entitlementsService
              .getOTPByEmail(this.userForm.controls['email'].value)
              .subscribe(() => {
                this.router.navigate(['./challenge/otp'], {
                  relativeTo: this.activatedRoute,
                  queryParams: {
                    email: this.userForm.controls['email'].value,
                    credential: response['credential_status'],
                  },
                });
                this.saveFormLoading = false;
              });
          },
          error: (err) => {
            if (err.error.message.includes('User does not exist')) {
              this.isUser = false;
            }
            else if (err.error.message.includes('User is locked') || err.error.message.includes('User is inactive')) {
              if (err.error.message.includes('User is locked')) {
                this.router.navigate(['locked']);
              }
              this.userError = err.error.message;
            }
            this.saveFormLoading = false;
          },
        });
    } else {
      this.userForm.controls['email'].markAsTouched();
    }
  }

  onChangeLoginType() {
    this.changeLoginTypeEmitter.emit(true);
  }
}
