<div class="flex flex-col pt-12 pb-8 px-4 rounded bg-primary justify-between grow">

    <div class="flex flex-col gap-8">
        <div class="flex flex-col items-center gap-2">
            <div>
                <mat-icon *ngIf="isDarkEnable" svgIcon="ic_dark_quicko_logo_circle"
                    class="h-[50px] w-[50px]"></mat-icon>
                <mat-icon *ngIf="!isDarkEnable" svgIcon="ic_quicko_logo_circle" class="h-[50px] w-[50px]"></mat-icon>
            </div>
            <div class="flex flex-col gap-2">
                <p class="font-medium text-xl text-text-primary">Login into <span
                        class="text-blue-900 font-semibold">Quicko</span></p>
                <p class="font-medium text-base text-center text-text-secondary">Welcome back!</p>
            </div>
        </div>
        <div class="flex flex-col gap-8">
            <form [formGroup]="userForm" (keyup.enter)="save()">
                <mat-form-field class="w-full" appearance="outline" hideRequiredMarker="true" subscriptSizing="dynamic">
                    <mat-label [ngClass]="{'text-text-primary':isDarkEnable}">Email</mat-label>
                    <input matInput formControlName="email" placeholder="Email"
                        class="font-medium text-base text-text-primary">
                    <mat-hint *ngIf="!isUser" class="font-medium text-xs text-alert-error-900">New here? Sign up
                        today.</mat-hint>
                    <mat-hint *ngIf="googleError" class="font-medium text-xs text-alert-error-900">Failed to signin. Try
                        again</mat-hint>
                    <mat-hint *ngIf="userError"
                        class="font-medium text-xs text-alert-error-900">{{userError}}</mat-hint>
                    <mat-error
                        *ngIf="userForm.controls['email'].errors && (userForm.controls['email'].dirty || userForm.controls['email'].touched)"
                        class="font-medium text-xs text-alert-error-900">
                        <mat-hint *ngIf="userForm.controls['email'].errors['required']">Email is required</mat-hint>
                        <mat-hint *ngIf="userForm.controls['email'].errors['email']">Enter a valid email</mat-hint>
                    </mat-error>
                </mat-form-field>
            </form>

            <button (click)="onChangeLoginType()" class="font-medium text-xs text-blue-900 self-start">Use Phone
                Number
                Instead</button>
        </div>
    </div>

    <div class="flex flex-col gap-6">
        <div class="flex flex-col gap-4 justify-center">
            <ng-container *ngIf="!saveFormLoading; else loading">
                <button #blueButton class="text-base font-medium text-white rounded bg-blue-900 w-full h-12"
                    (click)="save()">Proceed</button>
            </ng-container>
            <ng-container>
                <asl-google-signin-button type='standard' size='large' [width]="googleButtonWidth" text="continue_with"
                    logo_alignment="center"></asl-google-signin-button>
            </ng-container>
        </div>

        <p class="font-medium text-base text-text-secondary text-center">Don't have an account? <a
                routerLink="../signup" class="text-blue-900 underline">Create account</a></p>
    </div>

</div>

<ng-template #loading>
    <button class="text-base font-medium text-white rounded bg-blue-900 w-full h-12 flex justify-center items-center">
        <div class="loader" [ngStyle]="{'--loader-color': '#fff'}"></div>
    </button>
</ng-template>
<ng-template #blueLoading>
    <button
        class="flex text-base font-medium text-blue-900 border rounded border-blue-900 w-full h-12 justify-center items-center">
        <div class="loader" [ngStyle]="{'--loader-color': '#2962FF'}"></div>
    </button>
</ng-template>