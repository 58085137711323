import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({providedIn: 'root'})
export class ReferenceDataService {

  private endpoint;

  constructor(private httpClient: HttpClient) {
    this.endpoint = environment.reference_data_endpoint;
  }

  getCountries() {
    const url = this.endpoint + '/api/com-quicko-ref-countries?populate=*';
    return this.httpClient.get(url);
  }

  getApps() {
    const url = this.endpoint + '/api/com-quicko-user-apps?populate=*';
    return this.httpClient.get(url);
  }

}
