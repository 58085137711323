<section *ngIf="selectedCountry; else loadingMobile" id="email-login" class="flex flex-col items-center">
  <div class="flex flex-col pt-12 pb-8 px-4 container rounded border-stroke bg-primary">

    <div class="flex flex-col items-center gap-2">
      <div>
        <mat-icon *ngIf="isDarkEnable" svgIcon="ic_dark_quicko_logo_circle" class="h-[50px] w-[50px]"></mat-icon>
        <mat-icon *ngIf="!isDarkEnable" svgIcon="ic_quicko_logo_circle" class="h-[50px] w-[50px]"></mat-icon>
      </div>
      <p class="font-medium text-xl text-text-primary">Set my password</p>
      <p class="font-medium test-base text-text-secondary text-center">Please provide your registered mobile <br> number
      </p>
    </div>

    <form class="flex flex-col mt-8" [formGroup]="mobileForm" (keyup.enter)="save()">
      <mat-form-field appearance="outline" class="w-full">
        <img matPrefix src="{{selectedCountry.flag}}" class="h-5 w-7 ml-4 mr-3" />
        <mat-select [(value)]="selectedCountry" [ngClass]="{'dark':isDarkEnable}">
          <mat-option *ngFor="let c of countryList" [value]="c">
            <div class="flex flex-row">
              <img src="{{c.flag}}" class="h-5 w-7 mr-3" />
              <p>{{c.name}}</p>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-full mt-3" floatLabel="always">
        <mat-label [ngClass]="{'text-text-primary':isDarkEnable}">Mobile Number</mat-label>
        <p matTextPrefix class="font-medium text-base text-text-primary mx-1" *ngIf="selectedCountry">
          +{{selectedCountry.numericCode}}</p>
        <input matInput formControlName="mobile" class="font-medium text-base text-text-primary" type="number">
        <mat-hint *ngIf="!isUser" class="font-medium text-xs text-alert-error-900">This account does not
          exist</mat-hint>
        <mat-error
          *ngIf="mobileForm.controls['mobile'].errors && (mobileForm.controls['mobile'].dirty || mobileForm.controls['mobile'].touched)"
          class="font-medium text-xs text-alert-error-900">
          <mat-hint *ngIf="mobileForm.controls['mobile'].errors['required']">Required</mat-hint>
        </mat-error>
      </mat-form-field>
    </form>

    <button (click)="onChangeLoginType()" class="font-medium text-xs text-blue-900 mt-3 self-start">Use Email
      Instead</button>

    <ng-container *ngIf="!saveFormLoading; else loading">
      <button class="text-base font-medium text-white rounded bg-blue-900 w-full h-12 mt-[88px]"
        (click)="save()">Proceed</button>
    </ng-container>

    <p class="font-medium text-base text-text-secondary mt-6 text-center">Don't have an account? <a routerLink="/signup"
        class="text-blue-900 underline">Create account</a></p>
  </div>


  <ng-template #loading>
    <button
      class="text-base font-medium text-white rounded bg-blue-900 w-full h-12 flex justify-center mt-[88px] items-center">
      <div class="loader" [ngStyle]="{'--loader-color': '#fff'}"></div>
    </button>
  </ng-template>
</section>

<ng-template #loadingMobile>
  <div class="flex justify-center items-center h-screen">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
</ng-template>