import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { GoogleLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withFetch } from '@angular/common/http';
import { provideClientHydration } from '@angular/platform-browser';
import { NotLoggedIn, LoggedIn, RequestInterceptor } from '../../../../ngx-com-quicko-accounts/src/lib/services/auth.service';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideClientHydration(),
    provideAnimationsAsync(),
    NotLoggedIn,
    LoggedIn,
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '885852212396-2v5cpj2d6pm9u6pje8l2fbe07inlbbuv.apps.googleusercontent.com'
            ),
          },
        ],
      } as SocialAuthServiceConfig
    },
    provideAnimationsAsync()
  ]
};

