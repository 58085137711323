<section id="email-login" class="flex flex-col items-center">
    <div class="flex flex-col pt-12 pb-8 px-4 container rounded border-stroke bg-primary">

        <div class="flex flex-col items-center gap-2">
            <div>
                <mat-icon *ngIf="isDarkEnable" svgIcon="ic_dark_quicko_logo_circle"
                    class="h-[50px] w-[50px]"></mat-icon>
                <mat-icon *ngIf="!isDarkEnable" svgIcon="ic_quicko_logo_circle" class="h-[50px] w-[50px]"></mat-icon>
            </div>
            <p class="font-medium text-xl text-text-primary">Set my password</p>
            <p class="font-medium test-base text-text-secondary text-center">Please provide your registered email <br>
                address</p>
        </div>

        <form [formGroup]="userForm" (keyup.enter)="save()" class="mt-8">
            <mat-form-field class="w-full" appearance="outline">
                <mat-label [ngClass]="{'text-text-primary':isDarkEnable}">Email</mat-label>
                <input matInput formControlName="email" placeholder="Email"
                    class="font-medium text-base text-text-primary">
                <mat-hint *ngIf="!isUser" class="font-medium text-xs text-alert-error-900">This account does not
                    exist</mat-hint>
                <mat-error
                    *ngIf="userForm.controls['email'].errors && (userForm.controls['email'].dirty || userForm.controls['email'].touched)"
                    class="font-medium text-xs text-alert-error-900">
                    <mat-hint *ngIf="userForm.controls['email'].errors['required']">Required</mat-hint>
                    <mat-hint *ngIf="userForm.controls['email'].errors['email']">Invalid email</mat-hint>
                </mat-error>
            </mat-form-field>
        </form>

        <button (click)="onChangeLoginType()" class="font-medium text-xs text-blue-900 mt-3 self-start">Use Phone Number
            Instead</button>

        <div class="flex flex-col gap-4 justify-center mt-44">
            <ng-container *ngIf="!saveFormLoading; else loading">
                <button class="text-base font-medium text-white rounded bg-blue-900 w-full h-12"
                    (click)="save()">Proceed</button>
            </ng-container>
        </div>

        <p class="font-medium text-base text-text-secondary mt-6 text-center">Don't have an account? <a
                routerLink="/signup" class="text-blue-900 underline">Create account</a></p>

    </div>


    <ng-template #loading>
        <button
            class="text-base font-medium text-white rounded bg-blue-900 w-full h-12 flex justify-center items-center">
            <div class="loader" [ngStyle]="{'--loader-color': '#fff'}"></div>
        </button>
    </ng-template>
</section>