import { Routes } from '@angular/router';
import { LoggedIn, NotLoggedIn } from '../../../../ngx-com-quicko-accounts/src/lib/services/auth.service';
import { AccountRecoveryComponent, EmailSignupComponent, LinkMobileComponent, LockedComponent, LoginComponent, LogoutComponent, OAuthComponent, OtpLoginComponent, PasswordLoginComponent, ReAuthenticateComponent, SetPasswordComponent, VerifyEmailComponent, VerifyMobileComponent, VerifyOtpComponent } from 'ngx-com-quicko-accounts';

export const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },

    {
        path: 'login', canActivate: [NotLoggedIn],
        children: [
            {
                path: '',
                component: LoginComponent,
            },
            {
                path: 'challenge',
                children: [
                    {
                        path: '',
                        redirectTo: 'login',
                        pathMatch: 'full'
                    },
                    {
                        path: 'password',
                        component: PasswordLoginComponent,
                    },
                    {
                        path: 'otp',
                        component: OtpLoginComponent,
                    },
                ],
            },
        ],
    },
    {
        path: 'signup', canActivate: [NotLoggedIn],
        children: [
            {
                path: '',
                component: EmailSignupComponent,
            },
            {
                path: 'verify',
                component: VerifyEmailComponent,
            },
        ],
    },
    {
        path: 'link-mobile', canActivate: [LoggedIn],
        children: [
            {
                path: '',
                component: LinkMobileComponent,
            },
            {
                path: 'verify',
                component: VerifyMobileComponent,
            },
        ],
    },
    {
        path: 'set-password',
        children: [
            {
                path: '', canActivate: [LoggedIn],
                component: SetPasswordComponent,
            },
            {
                path: 'verify', canActivate: [NotLoggedIn],
                children: [
                    {
                        path: '',
                        component: AccountRecoveryComponent,
                    },
                    {
                        path: 'otp',
                        component: VerifyOtpComponent,
                    }
                ],
            },
        ]
    },
    {
        path: 'oauth/apps/:app_key/authorize', canActivate: [NotLoggedIn],
        component: OAuthComponent
    },
    {
        path: 'logout', canActivate: [LoggedIn], component: LogoutComponent
    },
    {
        path: 'reauthenticate', component: ReAuthenticateComponent
    },
    {
        path: 'locked', component: LockedComponent
    },
    {
        path: '**', redirectTo: 'login'
    }
];

