import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntitlementsService } from '../../../../services/entitlements.service';
import { AuthService } from '../../../../services/auth.service';
import { SharedService } from '../../../../services/shared.service';
import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NgOtpInputModule } from 'ng-otp-input';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  standalone: true,
  imports: [MatIconModule, ReactiveFormsModule, MatFormField, MatInputModule, NgClass, NgFor, NgIf, NgOtpInputModule, NgStyle]
})
export class VerifyOtpComponent implements OnInit {
  email: string | undefined;
  mobile: string | undefined;
  countryCode: string | undefined;
  otp: string | undefined;
  timeLeft = 30;
  interval: any;
  isDarkEnable: boolean = false;
  redirectUrl: string | undefined;
  saveFormLoading: boolean = false;
  errorMessage: string | undefined;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private entitlementsService: EntitlementsService,
    private authService: AuthService,
    private sharedService: SharedService
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.email = params['email'];
      this.mobile = params['mobile'];
      this.countryCode = params['countryCode'];
      this.otp = params['code'];
      if (this.otp) {
        this.verify();
      }
    });
  }

  ngOnInit() {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      if (cookie.split('=')[0].trim() == 'theme') {
        if (cookie.split('=')[1].trim() == 'dark') {
          this.isDarkEnable = true;
        } else {
          this.isDarkEnable = false;
        }
        break;
      }
    }

    this.sharedService.getRedirectUrl().subscribe((redirectUrl) => {
      this.redirectUrl = redirectUrl;
    })
  }

  verify() {
    if (this.email && this.otp) {
      this.saveFormLoading = true;
      this.entitlementsService.verifyResetPasswordEmail(this.email, this.otp).subscribe({
        next: (response: any) => {
          this.authService.setToken(response['access_token']);
          this.router.navigate(['set-password']);
          this.saveFormLoading = false;
        },
        error: (err) => {
          if (err.error.message.includes('Expired OTP') || err.error.message.includes('Invalid OTP')) {
            this.errorMessage = err.error.message;
          }
          else {
            this.router.navigate(['login']);
          }
          this.saveFormLoading = false;
        },
      });
    } else if (this.otp && this.mobile && this.countryCode) {
      this.saveFormLoading = true;
      this.entitlementsService
        .verifyResetPasswordMobile(this.mobile, this.countryCode, this.otp)
        .subscribe({
          next: (response: any) => {
            this.authService.setToken(response['access_token']);
            this.router.navigate(['set-password']);
            this.saveFormLoading = false;
          },
          error: (err) => {
            if (err.error.message.includes('Expired OTP') || err.error.message.includes('Invalid OTP')) {
              this.errorMessage = err.error.message;
            }
            else {
              this.router.navigate(['login']);
            }
            this.saveFormLoading = false;
          },
        });
    } else {
      this.errorMessage = 'Provide valid OTP';
    }
  }

  generateOtp() {
    if (this.email) {
      this.entitlementsService.getOTPByEmail(this.email);
    } else if (this.mobile && this.countryCode) {
      this.entitlementsService
        .getOTPByMobile(this.mobile, this.countryCode)
    }
  }

  onOtpChange(otp: string) {
    this.errorMessage = undefined;
    if (otp.length === 6) {
      this.otp = otp;
    }
  }

  resendOTP() {
    this.generateOtp();
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
        this.timeLeft = 30;
      }
    }, 1000);
  }

  back() {
    this.router.navigate(['../'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        email: this.email,
        mobile: this.mobile,
        countryCode: this.countryCode,
      },
    });
  }
}
