<section *ngIf="selectedCountry; else loadingMobile" id="email-login" class="flex flex-col items-center">
	<div class="flex flex-col pt-12 pb-8 px-4 container rounded border-stroke bg-primary">

		<div class="flex flex-col items-center gap-2">
			<div>
				<mat-icon *ngIf="isDarkEnable" svgIcon="ic_dark_quicko_logo_circle"
					class="h-[50px] w-[50px]"></mat-icon>
				<mat-icon *ngIf="!isDarkEnable" svgIcon="ic_quicko_logo_circle" class="h-[50px] w-[50px]"></mat-icon>
			</div>
			<p class="font-medium text-xl text-text-primary">Hey there, <span
					class="text-blue-900 font-semibold">{{name}}</span>
			</p>
			<p class="font-medium text-base text-center text-text-secondary">For additional security, please add &
				verify your
				phone number</p>
		</div>

		<form class="flex flex-col mt-8" [formGroup]="mobileForm" (keyup.enter)="save()">
			<mat-form-field appearance="outline" class="w-full">
				<img matPrefix src="{{selectedCountry.flag}}" class="h-5 w-7 ml-4 mr-3" />
				<mat-select [(value)]="selectedCountry" [ngClass]="{'dark':isDarkEnable}">
					<mat-option *ngFor="let c of countryList" [value]="c">
						<div class="flex flex-row">
							<img src="{{c.flag}}" class="h-5 w-7 mr-3" />
							<p>{{c.name}}</p>
						</div>
					</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field appearance="outline" class="w-full" floatLabel="always">
				<mat-label [ngClass]="{'text-text-primary':isDarkEnable}">Mobile Number</mat-label>
				<p matTextPrefix class="font-medium text-base text-text-primary mx-1" *ngIf="selectedCountry">
					+{{selectedCountry.numericCode}}</p>
				<input matInput formControlName="mobile" class="font-medium text-base text-text-primary" type="number">
				<mat-hint *ngIf="isUser" class="font-medium text-xs text-alert-error-900">Mobile already linked to
					another account</mat-hint>
				<mat-error
					*ngIf="mobileForm.controls['mobile'].errors && (mobileForm.controls['mobile'].dirty || mobileForm.controls['mobile'].touched)"
					class="font-medium text-xs text-alert-error-900">
					<mat-hint *ngIf="mobileForm.controls['mobile'].errors['required']">Required</mat-hint>
				</mat-error>
			</mat-form-field>
		</form>

		<div class="flex flex-col gap-4 justify-center mt-[68px] mb-[92px]">
			<ng-container *ngIf="!saveFormLoading; else loading">
				<button class="text-base font-medium text-white rounded bg-blue-900 w-full h-12"
					(click)="save()">Continue</button>
			</ng-container>
			<button
				class="flex text-base font-medium text-blue-900 border rounded border-blue-900 w-full h-12 justify-center items-center"
				(click)="skip()">Skip</button>
		</div>

	</div>



	<ng-template #loading>
		<button
			class="text-base font-medium text-white rounded bg-blue-900 w-full h-12 flex justify-center items-center">
			<div class="loader" [ngStyle]="{'--loader-color': '#fff'}"></div>
		</button>
	</ng-template>
</section>

<ng-template #loadingMobile>
	<div class="flex justify-center items-center h-screen">
		<mat-spinner [diameter]="50"></mat-spinner>
	</div>
</ng-template>