import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EntitlementsService } from '../../../services/entitlements.service';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { SharedService } from '../../../services/shared.service';
import { NgClass, NgForOf, NgIf, NgStyle } from '@angular/common';
import { MatFormField, MatLabel, MatHint, MatError } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { SocialLoginModule } from '@abacritt/angularx-social-login';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-link-mobile',
  templateUrl: './link-mobile.component.html',
  standalone: true,
  imports: [MatFormField, MatLabel, ReactiveFormsModule, NgClass, MatHint, MatError, MatIcon, NgIf, SocialLoginModule, MatInputModule, MatOption, MatSelect, NgForOf, NgStyle, MatProgressSpinnerModule]
})
export class LinkMobileComponent implements OnInit {
  email!: string;
  name!: string;
  mobileForm: FormGroup;
  countryCode: number = 91;
  countryList: any;
  selectedCountry: any;
  isUser: boolean = false;
  isDarkEnable: boolean = false;
  redirectUrl: string | undefined;
  saveFormLoading: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private referenceDataService: ReferenceDataService,
    private entitlementsService: EntitlementsService,
    private sharedService: SharedService
  ) {
    this.mobileForm = new FormGroup({
      mobile: new FormControl('', [Validators.required]),
    });
    this.activatedRoute.queryParams.subscribe((params) => {
      this.email = params['email'];
      this.name = params['name'];
      this.mobileForm.controls['mobile'].setValue(params['mobile']);
      if (params['countryCode']) {
        this.countryCode = parseInt(params['countryCode']);
      }
    });

    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      if (cookie.split('=')[0].trim() == 'theme') {
        if (cookie.split('=')[1].trim() == 'dark') {
          this.isDarkEnable = true;
        } else {
          this.isDarkEnable = false;
        }
        break;
      }
    }

    this.sharedService.getRedirectUrl().subscribe((redirectUrl) => {
      this.redirectUrl = redirectUrl;
    })
  }

  ngOnInit() {
    this.referenceDataService.getCountries().subscribe((response: any) => {
      this.countryList = response['data'];
      this.selectedCountry = this.countryList.find(
        (c: any) => c.numericCode === this.countryCode
      );
      if (!this.selectedCountry) {
        this.selectedCountry = this.countryList.find(
          (c: any) => c.numericCode === 91
        );
      }
    });
    this.mobileForm.controls['mobile'].valueChanges.subscribe(() => {
      this.isUser = false;
    });
  }

  save() {
    if (this.mobileForm.valid && this.selectedCountry) {
      this.saveFormLoading = true;
      this.entitlementsService
        .getOTPByNewMobile(
          this.email,
          this.mobileForm.controls['mobile'].value,
          this.selectedCountry.numericCode
        )
        .subscribe({
          next: () => {
            this.router.navigate(['verify'], {
              relativeTo: this.activatedRoute,
              queryParams: {
                email: this.email,
                name: this.name,
                mobile: this.mobileForm.controls['mobile'].value,
                countryCode: this.selectedCountry.numericCode,
              },
            });
            this.saveFormLoading = false;
          },
          error: (err) => {
            if (err.error.message.includes('User already exist') || err.error.message.includes('User is locked')) {
              this.isUser = true;
            }
            this.saveFormLoading = false;
          },
        });
    } else {
      this.mobileForm.controls['mobile'].markAsTouched();
    }
  }

  skip() {
    if (this.redirectUrl) {
      window.location.href = window.atob(this.redirectUrl);
    }
    else {
      window.location.href = environment.income_tax_url;
    }
  }
}
