<section id="email-login" class="flex flex-col items-center">
  <div class="flex flex-col pt-12 pb-8 px-4 container rounded border-stroke bg-primary">

    <div class="flex flex-col items-center gap-2">
      <div>
        <mat-icon *ngIf="isDarkEnable" svgIcon="ic_dark_quicko_logo_circle" class="h-[50px] w-[50px]"></mat-icon>
        <mat-icon *ngIf="!isDarkEnable" svgIcon="ic_quicko_logo_circle" class="h-[50px] w-[50px]"></mat-icon>
      </div>
      <p class="font-medium text-xl text-text-primary">Login into <span
          class="text-blue-900 font-semibold">Quicko</span></p>
      <p class="font-medium text-base text-center text-text-secondary">Please enter the OTP sent to <br><span
          class="text-blue-900 font-semibold" *ngIf="email">{{email}}</span><span class="text-blue-900 font-semibold"
          *ngIf="mobile">+{{countryCode}} {{mobile}}</span></p>
    </div>

    <div class="flex flex-col items-center justify-center mx-auto">
      <ng-otp-input (onInputChange)="onOtpChange($event)" (keyup.enter)="verify()" [config]="{
        length: 6,
        allowNumbersOnly: true,
        inputStyles: {
          'font-size': '20px',
          'font-weight': '500',
          width: '46px',
          height: '56px',
          'border-color': errorMessage ? '#E51717' : ''
        }
      }" class="mt-8 w-full justify-center" [ngClass]="{'dark':isDarkEnable}">
      </ng-otp-input>
    </div>
    <p [ngClass]="errorMessage ? 'mb-2' : 'mb-6'"
      class="font-medium text-xs text-alert-error-900 ml-4 justify-center items-center text-center w-full">
      {{errorMessage}}
    </p>

    <div [ngClass]="{'mb-8':!credential}" class="flex flex-col items-start">
      <button (click)="resendOTP()" [disabled]="timeLeft != 30"
        [ngClass]="timeLeft == 30 ? 'text-blue-900' : 'text-blue-grey-600'" class="font-medium text-base">Resend
        OTP<span *ngIf="timeLeft != 30"> (Available in {{timeLeft}}s)</span></button>
      <button *ngIf="credential=='active'" (click)="navigateToPassword()"
        class="font-medium text-sm text-blue-900 mt-6">Login
        with
        Password</button>
    </div>

    <div class="flex flex-col gap-4 justify-center mt-24 mb-12">
      <ng-container *ngIf="!saveFormLoading; else loading">
        <button class="text-base font-medium text-white rounded bg-blue-900 w-full h-12" (click)="verify()">Log
          In</button>
      </ng-container>
      <button class="text-base font-medium text-blue-900 border rounded border-blue-900 w-full h-12"
        (click)="back()">Back</button>
    </div>

  </div>


  <ng-template #loading>
    <button class="text-base font-medium text-white rounded bg-blue-900 w-full h-12 flex justify-center items-center">
      <div class="loader" [ngStyle]="{'--loader-color': '#fff'}"></div>
    </button>
  </ng-template>
</section>