import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { EntitlementsService } from '../../../services/entitlements.service';
import { NgClass, NgIf, NgStyle } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormField, MatLabel, MatHint, MatError } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { SocialLoginModule } from '@abacritt/angularx-social-login';
import { NgOtpInputModule } from 'ng-otp-input';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  standalone: true,
  imports: [MatFormField, MatLabel, ReactiveFormsModule, NgClass, MatHint, MatError, MatIcon, NgIf, SocialLoginModule, MatInputModule, NgOtpInputModule, NgStyle]
})
export class VerifyEmailComponent implements OnInit {
  email!: string;
  name!: string;
  otp: string | undefined;
  timeLeft = 30;
  interval: any;
  isDarkEnable: boolean = false;
  saveFormLoading: boolean = false;
  errorMessage: string | undefined;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private entitlementsService: EntitlementsService,
    private authService: AuthService
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.email = params['email'];
      this.name = params['name'];
      if (!this.email) {
        this.router.navigate(['../'], { relativeTo: this.activatedRoute });
      }
    });
  }

  ngOnInit() {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      if (cookie.split('=')[0].trim() == 'theme') {
        if (cookie.split('=')[1].trim() == 'dark') {
          this.isDarkEnable = true;
        } else {
          this.isDarkEnable = false;
        }
        break;
      }
    }
  }

  verify() {
    if (this.email && this.otp) {
      this.saveFormLoading = true;
      this.entitlementsService.signupOTPVerify(this.email, this.otp).subscribe({
        next: (response: any) => {
          this.authService.setToken(response['access_token']);
          this.router.navigate(['../../link-mobile'], {
            queryParams: {
              email: this.email,
              name: this.name,
            },
          });
          this.saveFormLoading = false;
        },
        error: (err) => {
          if (err.error.message.includes('Expired OTP') || err.error.message.includes('Invalid OTP')) {
            this.errorMessage = err.error.message;
          }
          else {
            this.router.navigate(['signup']);
          }
          this.saveFormLoading = false;
        },
      });
    } else {
      this.errorMessage = 'Provide valid OTP';
    }
  }

  generateOtp() {
    this.entitlementsService.signup(this.email).subscribe({
      next: () => {
        this.saveFormLoading = false;
      },
      error: () => {
        this.router.navigate(['signup']);
      },
    });
  }

  onOtpChange(otp: string) {
    this.errorMessage = undefined;
    if (otp.length === 6) {
      this.otp = otp;
    }
  }

  resendOTP() {
    this.generateOtp();
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
        this.timeLeft = 30;
      }
    }, 1000);
  }

  back() {
    this.router.navigate(['signup'], { queryParams: { email: this.email } });
  }

  navigateToLogin() {
    this.router.navigate(['login'], { queryParams: { email: this.email } });
  }
}
