import { Component } from '@angular/core';
import { ActivatedRoute, RouterModule, RouterOutlet } from '@angular/router';
import { EmailLoginComponent } from 'ngx-com-quicko-accounts';
import { environment } from '../../../../ngx-com-quicko-accounts/src/lib/environments/environment';
import { SessionCookieService } from '../../../../ngx-com-quicko-accounts/src/lib/services/session-cookie.service';
import { AffiliateService } from '../../../../ngx-com-quicko-accounts/src/lib/services/affiliate.service';
import { SharedService } from '../../../../ngx-com-quicko-accounts/src/lib/services/shared.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, EmailLoginComponent, RouterModule, NgClass],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  isDarkEnable: boolean = false;

  constructor(private sessionCookieService: SessionCookieService, private affiliateService: AffiliateService, private sharedService: SharedService, private activatedRoute: ActivatedRoute, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {

    iconRegistry.addSvgIcon(
      'ic_logo',
      sanitizer.bypassSecurityTrustResourceUrl(environment.ic_quicko_logo)
    );
    iconRegistry.addSvgIcon(
      'ic_quicko_word_logo',
      sanitizer.bypassSecurityTrustResourceUrl(environment.ic_quicko_word_logo)
    );
    iconRegistry.addSvgIcon(
      'ic_google_logo',
      sanitizer.bypassSecurityTrustResourceUrl(environment.ic_google_logo)
    );

    // product logos
    iconRegistry.addSvgIcon(
      'ic_quicko_logo_circle',
      sanitizer.bypassSecurityTrustResourceUrl(
        environment.ic_quicko_logo_circle
      )
    );
    iconRegistry.addSvgIcon(
      'ic_dark_quicko_logo_circle',
      sanitizer.bypassSecurityTrustResourceUrl(
        environment.ic_dark_quicko_logo_circle
      )
    );
    iconRegistry.addSvgIcon(
      'ic_meet_logo_circle',
      sanitizer.bypassSecurityTrustResourceUrl(environment.ic_meet_logo_circle)
    );

    // social icons
    iconRegistry.addSvgIcon(
      'ic_twitter',
      sanitizer.bypassSecurityTrustResourceUrl(environment.ic_twitter)
    );
    iconRegistry.addSvgIcon(
      'ic_linkedin',
      sanitizer.bypassSecurityTrustResourceUrl(environment.ic_linkedin)
    );
    iconRegistry.addSvgIcon(
      'ic_instagram',
      sanitizer.bypassSecurityTrustResourceUrl(environment.ic_instagram)
    );

    this.activatedRoute.queryParams.subscribe((params) => {
      let affiliateId = params['affiliate_id'];

      if (affiliateId && !this.affiliateService.getCookie()) {
        this.affiliateService.setCookie(affiliateId);
      }

      if (params['utm_source']) {
        this.sessionCookieService.setCookie("UTM_SOURCE", params['utm_source']);
      }

      if (params['utm_medium']) {
        this.sessionCookieService.setCookie("UTM_MEDIUM", params['utm_medium']);
      }

      if (params['utm_campaign']) {
        this.sessionCookieService.setCookie("UTM_CAMPAIGN", params['utm_campaign']);
      }

      if (params['utm_content']) {
        this.sessionCookieService.setCookie("UTM_CONTENT", params['utm_content']);
      }

      if (params['utm_id']) {
        this.sessionCookieService.setCookie("UTM_ID", params['utm_id']);
      }

      if (params['utm_term']) {
        this.sessionCookieService.setCookie("UTM_TERM", params['utm_term']);
      }

      if (params['redirect']) {
        this.sharedService.setRedirectUrl(params['redirect']);
      }
    });
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      let themeObject = params["theme"]?.toString();

      if (themeObject) {
        this.sessionCookieService.setCookie("theme", themeObject);
      }
    })

    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      if (cookie.split('=')[0].trim() == 'theme') {
        const themeCookie = cookie.split('=')[1].trim();
        const decodedThemeObject = JSON.parse(atob(decodeURIComponent(themeCookie).trim()));
        if (decodedThemeObject.mode.toLowerCase() == 'dark') {
          this.isDarkEnable = true;
        }
        else {
          this.isDarkEnable = false;
        }
        break;
      }
    }
  }
}
