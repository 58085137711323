import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { OAuthService } from '../../services/oauth.service';
import { ReferenceDataService } from '../../services/reference-data.service';
import { SharedService } from '../../services/shared.service';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-oauth',
  templateUrl: './oauth.component.html',
  standalone: true,
  imports: [MatIconModule, NgIf, MatProgressBarModule, MatProgressSpinnerModule]
})
export class OAuthComponent implements OnInit {

  requestToken: string | null = '';
  queryParamSubscription: Subscription;
  isDarkEnable: boolean = false;
  appKey: string | null = '';
  redirectUrl: string | undefined;
  app: any;
  apps: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private oAuthService: OAuthService,
    private authService: AuthService,
    private referenceDataService: ReferenceDataService,
    private router: Router,
    private sharedService: SharedService,
  ) {

    this.queryParamSubscription = this.activatedRoute.queryParamMap.subscribe(queryParams => {
      if (queryParams.get('request_token') != null) {
        this.requestToken = queryParams.get('request_token');
      }
      else {
        this.router.navigate(['login']);
      }
    })

    this.activatedRoute.paramMap.subscribe(paramMap => {
      if (paramMap.get('app_key') != null) {
        this.appKey = paramMap.get('app_key');
      }
      else {
        this.router.navigate(['login']);
      }
    })

    if (this.appKey == "com-indiainfoline") {
      this.requestToken = window.btoa(this.requestToken as string);
    }

    this.sharedService.getRedirectUrl().subscribe((redirectUrl) => {
      this.redirectUrl = redirectUrl;
    });

    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      if (cookie.split('=')[0].trim() == 'theme') {
        if (cookie.split('=')[1].trim() == 'dark') {
          this.isDarkEnable = true;
        } else {
          this.isDarkEnable = false;
        }
        break;
      }
    }
  }

  ngOnInit() {

    this.referenceDataService.getApps().subscribe((response: any) => {
      this.apps = response['data'];
      this.app = this.apps.find(
        (a: any) => a.app_key === this.appKey
      );
      if (!this.app) {
        this.router.navigate(['login']);
      }
    })

    this.oAuthService.authorizeBroker(this.requestToken as string, this.appKey as string).subscribe({
      next: (response: any) => {
        this.authService.setToken(response['access_token'])

        if (this.redirectUrl) {
          window.location.href = window.atob(this.redirectUrl);
        }
        else {
          window.location.href = environment.income_tax_url;
        }
      },
      error: (err) => {
        this.router.navigate(['/login']);
      }
    })
  }

  ngOnDestory() {
    this.queryParamSubscription.unsubscribe();
  }

}
