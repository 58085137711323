import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { EntitlementsService } from '../../../../services/entitlements.service';
import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { MatFormField } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-email-recovery',
  templateUrl: './email-recovery.component.html',
  standalone: true,
  imports: [MatIconModule, ReactiveFormsModule, MatFormField, MatInputModule, NgClass, NgFor, NgIf, RouterModule, NgStyle]
})
export class EmailRecoveryComponent implements OnInit {

  userForm: FormGroup;
  isUser: boolean = true;
  isDarkEnable: boolean = false;
  saveFormLoading: boolean = false;

  @Output() changeLoginTypeEmitter: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private entitlementsService: EntitlementsService
  ) {
    this.userForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      this.userForm.controls['email'].setValue(params['email']);
    });
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      if (cookie.split('=')[0].trim() == 'theme') {
        if (cookie.split('=')[1].trim() == 'dark') {
          this.isDarkEnable = true;
        } else {
          this.isDarkEnable = false;
        }
        break;
      }
    }
  }

  ngOnInit() {
    this.userForm.controls['email'].valueChanges.subscribe(() => {
      this.isUser = true;
    });
  }

  save() {
    if (this.userForm.valid) {
      this.saveFormLoading = true;
      this.entitlementsService
        .getUserMetadata(this.userForm.controls['email'].value)
        .subscribe({
          next: (response: any) => {
            this.entitlementsService
              .resetPasswordEmail(this.userForm.controls['email'].value)
              .subscribe(() => {
                this.router.navigate(['otp'], {
                  relativeTo: this.activatedRoute,
                  queryParams: {
                    email: this.userForm.controls['email'].value
                  },
                });
                this.saveFormLoading = false;
              });
          },
          error: (err) => {
            if (err.error.message.includes('User does not exist')) {
              this.isUser = false;
            }
            this.saveFormLoading = false;
          },
        });
    } else {
      this.userForm.controls['email'].markAsTouched();
    }
  }

  onChangeLoginType() {
    this.changeLoginTypeEmitter.emit(true);
  }

}
