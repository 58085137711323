<section id="email-login" class="flex flex-col items-center">
  <div class="flex flex-col pt-12 pb-8 px-4 border container rounded border-stroke bg-primary">

    <div class="flex flex-col items-center gap-2">
      <div>
        <mat-icon *ngIf="isDarkEnable" svgIcon="ic_dark_quicko_logo_circle" class="h-[50px] w-[50px]"></mat-icon>
        <mat-icon *ngIf="!isDarkEnable" svgIcon="ic_quicko_logo_circle" class="h-[50px] w-[50px]"></mat-icon>
      </div>
      <p class="font-medium text-xl text-text-primary">Set my password</p>
      <p class="font-medium text-base text-center text-text-secondary">Please enter the OTP sent to <br><span
          class="text-blue-900 font-semibold" *ngIf="email">{{email}}</span><span class="text-blue-900 font-semibold"
          *ngIf="mobile">+{{countryCode}} {{mobile}}</span></p>
    </div>

    <ng-otp-input (onInputChange)="onOtpChange($event)" (keydown.enter)="verify()" [config]="{length:6, allowNumbersOnly: true, inputStyles: {
      'font-size': '20px',
      'font-weight': '500',
      'width': '46px',
      'height': '56px'  
      }}" class="mt-8 text-text-primary bg-primary" [ngClass]="{'dark':isDarkEnable}">
    </ng-otp-input>
    <p [ngClass]="errorMessage ? 'mb-2' : 'mb-6'" class="font-medium text-xs text-alert-error-900 ml-4">{{errorMessage}}
    </p>

    <button (click)="resendOTP()" [disabled]="timeLeft != 30"
      [ngClass]="timeLeft == 30 ? 'text-blue-900' : 'text-blue-grey-600'"
      class="font-medium text-base self-start">Resend
      OTP<span *ngIf="timeLeft != 30"> (Available in {{timeLeft}})</span></button>

    <div class="flex flex-col gap-4 justify-center mt-28 mb-12">
      <ng-container *ngIf="!saveFormLoading; else loading">
        <button class="text-base font-medium text-white rounded bg-blue-900 w-full h-12"
          (click)="verify()">Verify</button>
      </ng-container>
      <button class="text-base font-medium text-blue-900 border rounded border-blue-900 w-full h-12"
        (click)="back()">Back</button>
    </div>

  </div>


  <ng-template #loading>
    <button class="text-base font-medium text-white rounded bg-blue-900 w-full h-12 flex justify-center items-center">
      <div class="loader" [ngStyle]="{'--loader-color': '#fff'}"></div>
    </button>
  </ng-template>
</section>