import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { GoogleLoginProvider, GoogleSigninButtonModule, SocialAuthService, SocialLoginModule } from '@abacritt/angularx-social-login';
import { AuthService } from '../../../services/auth.service';
import { EntitlementsService } from '../../../services/entitlements.service';
import { OAuthService } from '../../../services/oauth.service';
import { SharedService } from '../../../services/shared.service';
import { NgClass, NgIf, NgStyle } from '@angular/common';
import { MatFormField, MatLabel, MatHint, MatError } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-email-signup',
  templateUrl: './email-signup.component.html',
  standalone: true,
  imports: [MatFormField, MatLabel, ReactiveFormsModule, NgClass, MatHint, MatError, MatIcon, NgIf, SocialLoginModule, MatInputModule, RouterModule, NgStyle, GoogleSigninButtonModule]
})
export class EmailSignupComponent implements OnInit, AfterViewInit {
  isDarkEnable: boolean = false;
  userForm: FormGroup;
  isUser: boolean = false;
  googleError: boolean = false;
  redirectUrl: string | undefined;
  signUploading: boolean = false;
  googleLoading: boolean = false;
  userError: string | undefined;
  googleButtonWidth: number;

  @ViewChild('blueButton')
  blueButton!: ElementRef<HTMLButtonElement>;

  constructor(
    private router: Router,
    private entitlementsService: EntitlementsService,
    private socialAuthService: SocialAuthService,
    private oauthService: OAuthService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService,
  ) {

    this.googleButtonWidth = 326;

    this.userForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      this.userForm.controls['email'].setValue(params['email']);
      this.userForm.controls['firstName'].setValue(params['name']);
    });

    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      if (cookie.split('=')[0].trim() == 'theme') {
        if (cookie.split('=')[1].trim() == 'dark') {
          this.isDarkEnable = true;
        } else {
          this.isDarkEnable = false;
        }
        break;
      }
    }

    this.sharedService.getRedirectUrl().subscribe((redirectUrl) => {
      this.redirectUrl = redirectUrl;
    })
  }

  ngAfterViewInit(): void {
    this.googleButtonWidth = this.blueButton?.nativeElement?.clientWidth!
  }


  ngOnInit() {
    this.socialAuthService.authState.subscribe((user) => {
      if (user) {
        this.signUploading = true;
        this.oauthService
          .authorizeGoogle(
            user['idToken'],
            user['email'],
            user['firstName'] ? user['firstName'] : null,
            user['lastName'] ? user['lastName'] : null
          )
          .subscribe({
            next: (response: any) => {
              this.authService.setToken(response['access_token'])

              if (this.redirectUrl) {
                window.location.href = window.atob(this.redirectUrl);
              }
              else {
                window.location.href = environment.income_tax_url;
              };
              this.googleLoading = false;
              this.signUploading = true;
            },
            error: (err) => {
              this.signUploading = true;
              if (err.error.message.includes('User is locked')) {
                this.router.navigate(['./locked']);
              }
              this.googleError = true;
              this.googleLoading = false;
            }
          });
      }
    })

    this.userForm.controls['email'].valueChanges.subscribe(() => {
      this.isUser = false;
      this.googleError = false;
      this.userError = undefined;
    });
  }

  signUp() {
    if (this.userForm.valid) {
      this.signUploading = true;
      this.entitlementsService.signup(
        this.userForm.controls['email'].value,
        this.userForm.controls['firstName'].value,
        this.userForm.controls['lastName'].value
      ).subscribe({
        next: () => {
          this.router.navigate(['verify'], {
            relativeTo: this.activatedRoute,
            queryParams: {
              email: this.userForm.controls['email'].value,
              name: this.userForm.controls['firstName'].value,
            },
          });
          this.signUploading = false;
        },
        error: (err) => {
          if (err.error.message.includes('User already exist')) {
            this.isUser = true;
          }
          else if (err.error.message.includes('User is locked') || err.error.message.includes('User is inactive')) {
            this.userError = err.error.message;
          }
          this.signUploading = false;
        },
      });
    } else {
      this.userForm.controls['email'].markAsTouched();
      this.userForm.controls['firstName'].markAsTouched();
      this.userForm.controls['lastName'].markAsTouched();
    }
  }

  signUpGoogle() {
    this.googleLoading = true;
    this.socialAuthService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((user) => {
        this.oauthService
          .authorizeGoogle(
            user['idToken'],
            user['email'],
            user['firstName'] ? user['firstName'] : null,
            user['lastName'] ? user['lastName'] : null
          )
          .subscribe({
            next: (response: any) => {
              this.authService.setToken(response['access_token'])

              if (this.redirectUrl) {
                window.location.href = window.atob(this.redirectUrl);
              }
              else {
                window.location.href = environment.income_tax_url;
              }
              this.googleLoading = false;
            },
            error: (err) => {
              if (err.error.message.includes('User is locked')) {
                this.router.navigate(['./locked']);
              }
              this.googleError = true;
              this.googleLoading = false;
            }
          });
      });
  }
}
