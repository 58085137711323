import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormField, MatLabel, MatHint, MatError } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatIcon } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { GoogleLoginProvider, GoogleSigninButtonModule, SocialAuthService } from '@abacritt/angularx-social-login';
import { AuthService } from '../../../services/auth.service';
import { OAuthService } from '../../../services/oauth.service';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { EntitlementsService } from '../../../services/entitlements.service';
import { SharedService } from '../../../services/shared.service';
import { NgClass, NgForOf, NgIf, NgStyle } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import _ from "lodash";
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-mobile-login',
  templateUrl: './mobile-login.component.html',
  standalone: true,
  imports: [MatFormField, MatLabel, ReactiveFormsModule, NgClass, MatHint, MatError, MatIcon, NgIf, MatOption, MatSelect, NgForOf, MatOption, MatInputModule, RouterModule, NgStyle, MatProgressSpinnerModule, GoogleSigninButtonModule]
})
export class MobileLoginComponent implements OnInit, AfterViewInit {
  mobileForm: FormGroup;
  countryCode: number = 91;
  countryList: any;
  selectedCountry: any;
  isDarkEnable: boolean = false;
  isUser: boolean = true;
  googleError: boolean = false;
  redirectUrl: string | undefined;
  saveFormLoading: boolean = false;
  googleLogin: boolean = false;
  userError: string | undefined;
  googleButtonWidth: number;

  @Output() changeLoginTypeEmitter: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('blueButton')
  blueButton!: ElementRef<HTMLButtonElement>;

  constructor(
    private router: Router,
    private authService: AuthService,
    private oauthService: OAuthService,
    private socialAuthService: SocialAuthService,
    private referenceDataService: ReferenceDataService,
    private entitlementsService: EntitlementsService,
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService
  ) {

    this.googleButtonWidth = 326;

    this.mobileForm = new FormGroup({
      mobile: new FormControl('', [Validators.required]),
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      this.mobileForm.controls['mobile'].setValue(params['mobile']);
      if (params['countryCode']) {
        this.countryCode = parseInt(params['countryCode']);
      }
    });

    this.sharedService.getRedirectUrl().subscribe((redirectUrl) => {
      this.redirectUrl = redirectUrl;
    })
  }

  ngAfterViewInit() {
    this.googleButtonWidth = this.blueButton!.nativeElement.clientWidth;
  }

  ngOnInit() {
    this.socialAuthService.authState.subscribe((user) => {
      if (user) {
        this.saveFormLoading = true;
        this.oauthService
          .authorizeGoogle(
            user['idToken'],
            user['email'],
            user['firstName'] ? user['firstName'] : null,
            user['lastName'] ? user['lastName'] : null
          )
          .subscribe({
            next: (response: any) => {
              this.authService.setToken(response['access_token'])

              if (this.redirectUrl) {
                window.location.href = window.atob(this.redirectUrl);
              }
              else {
                window.location.href = environment.income_tax_url;
              };
              this.googleLogin = false;
            },
            error: (err) => {
              if (err.error.message.includes('User is locked')) {
                this.router.navigate(['./locked']);
              }
              this.googleError = true;
              this.googleLogin = false;
            }
          });
      }
    })


    this.referenceDataService.getCountries().subscribe((response: any) => {
      this.countryList = response['data'];
      this.countryList = _.orderBy(this.countryList, ['name'], ['asc']);
      this.selectedCountry = this.countryList.find(
        (c: any) => c.numericCode === this.countryCode
      );
      if (!this.selectedCountry) {
        this.selectedCountry = this.countryList.find(
          (c: any) => c.numericCode === 91
        );
      }
    });



    this.mobileForm.controls['mobile'].valueChanges.subscribe(() => {
      this.isUser = true;
      this.googleError = false;
      this.userError = undefined;
    });

    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      if (cookie.split('=')[0].trim() == 'theme') {
        if (cookie.split('=')[1].trim() == 'dark') {
          this.isDarkEnable = true;
        } else {
          this.isDarkEnable = false;
        }
        break;
      }
    }

  }

  save() {
    if (this.mobileForm.valid && this.selectedCountry) {
      this.saveFormLoading = true;
      this.entitlementsService
        .getUserMetadataByMobile(
          this.selectedCountry.numericCode.toString(),
          this.mobileForm.controls['mobile'].value,
        )
        .subscribe({
          next: (response: any) => {
            this.entitlementsService
              .getOTPByMobile(
                this.mobileForm.controls['mobile'].value,
                this.selectedCountry.numericCode.toString()
              )
              .subscribe(() => {
                this.router.navigate(['./challenge/otp'], {
                  relativeTo: this.activatedRoute,
                  queryParams: {
                    mobile: this.mobileForm.controls['mobile'].value,
                    countryCode: this.selectedCountry.numericCode,
                    credential: response['credential_status']
                  },
                });
                this.saveFormLoading = false;
              });
          },
          error: (err) => {
            if (err.error.message.includes('User does not exist')) {
              this.isUser = false;
            }
            else if (err.error.message.includes('User is locked') || err.error.message.includes('User is inactive')) {
              this.userError = err.error.message;
            }
            this.saveFormLoading = false;
          },
        });
    } else {
      this.mobileForm.controls['mobile'].markAsTouched();
    }
  }

  loginWithGoogle() {
    this.googleLogin = true;
    this.socialAuthService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((user) => {
        this.oauthService
          .authorizeGoogle(
            user['idToken'],
            user['email'],
            user['firstName'] ? user['firstName'] : null,
            user['lastName'] ? user['lastName'] : null
          )
          .subscribe({
            next: (response: any) => {
              this.authService.setToken(response['access_token'])

              if (this.redirectUrl) {
                window.location.href = window.atob(this.redirectUrl);
              }
              else {
                window.location.href = environment.income_tax_url;
              };
              this.googleLogin = false;
            },
            error: (err) => {
              if (err.error.message.includes('User is locked')) {
                this.router.navigate(['./locked']);
              }
              this.googleError = true;
              this.googleLogin = false;
            }
          });
      });
  }

  onChangeLoginType() {
    this.changeLoginTypeEmitter.emit(true);
  }
}
