import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EntitlementsService } from '../../../services/entitlements.service';
import { SharedService } from '../../../services/shared.service';
import { MatIconModule } from '@angular/material/icon';
import { MatFormField } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  standalone: true,
  imports: [MatIconModule, ReactiveFormsModule, MatFormField, MatInputModule, NgClass, NgFor, NgIf, NgStyle]
})
export class SetPasswordComponent implements OnInit {
  passwordForm: FormGroup;
  validations: any;
  isDarkEnable: boolean = false;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  redirectUrl: string | undefined;
  saveFormLoading: boolean = false;

  constructor(
    private router: Router,
    private entitlementService: EntitlementsService,
    private sharedService: SharedService,
  ) {
    this.passwordForm = new FormGroup({
      newPassword: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required]),
    });

    this.validations = {
      samePassword: {
        bool: false,
        message: 'Passwords do not match',
      },
      minLength: {
        bool: false,
        message: 'Must be at least 8 characters long',
      },
      hasLowercase: {
        bool: false,
        message: 'Must include at least one lowercase character',
      },
      hasUppercase: {
        bool: false,
        message: 'Must include at least one uppercase character',
      },
      hasNumber: {
        bool: false,
        message: 'Must include at least one number',
      },
      hasSpecialChar: {
        bool: false,
        message: 'Must include at least one special character',
      },
    };

    this.sharedService.getRedirectUrl().subscribe((redirectUrl) => {
      this.redirectUrl = redirectUrl;
    })
  }

  ngOnInit() {
    this.passwordForm.valueChanges.subscribe(() => {
      this.checkValidations();
    });
  }

  save() {
    if (this.passwordForm.valid && this.checkValidations()) {
      this.saveFormLoading = true;
      this.entitlementService.setPassword(this.passwordForm.controls['newPassword'].value).subscribe({
        next: () => {
          if (this.redirectUrl) {
            window.location.href = window.atob(this.redirectUrl);
          }
          else {
            window.location.href = environment.income_tax_url;
          }
          this.saveFormLoading = false;
        },
        error: () => {
          if (this.redirectUrl) {
            window.location.href = window.atob(this.redirectUrl);
          }
          else {
            window.location.href = environment.income_tax_url;
          }
          this.saveFormLoading = false;
        },
      })
    }
  }

  checkValidations() {
    const newPassword = this.passwordForm.controls['newPassword'].value;
    // check all validations
    let allValidations: boolean = true;

    // match passwords
    if (
      this.passwordForm.controls['newPassword'].value ===
      this.passwordForm.controls['confirmPassword'].value
    ) {
      this.validations['samePassword'].bool = true;
    } else {
      this.validations['samePassword'].bool = false;
      allValidations = false;
    }
    // check length
    if (newPassword.length >= 8) {
      this.validations['minLength'].bool = true;
    } else {
      this.validations['minLength'].bool = false;
      allValidations = false;
    }

    // check lowercase
    if (/[a-z]/.test(newPassword)) {
      this.validations['hasLowercase'].bool = true;
    } else {
      this.validations['hasLowercase'].bool = false;
      allValidations = false;
    }

    // check uppercase
    if (/[A-Z]/.test(newPassword)) {
      this.validations['hasUppercase'].bool = true;
    } else {
      this.validations['hasUppercase'].bool = false;
      allValidations = false;
    }

    // check number
    if (/\d/.test(newPassword)) {
      this.validations['hasNumber'].bool = true;
    } else {
      this.validations['hasNumber'].bool = false;
      allValidations = false;
    }

    // check special character
    if (/[!@#$%^&*]/.test(newPassword)) {
      this.validations['hasSpecialChar'].bool = true;
    } else {
      this.validations['hasSpecialChar'].bool = false;
      allValidations = false;
    }
    return allValidations;
  }

  objectKeys(obj: any) {
    return Object.keys(obj);
  }
}
