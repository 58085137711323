<section id="email-login" class="flex flex-col items-center">
  <div class="flex flex-col pt-12 pb-8 px-4 container rounded border-stroke bg-primary">

    <div class="flex flex-col items-center gap-2">
      <div>
        <mat-icon *ngIf="isDarkEnable" svgIcon="ic_dark_quicko_logo_circle" class="h-[50px] w-[50px]"></mat-icon>
        <mat-icon *ngIf="!isDarkEnable" svgIcon="ic_quicko_logo_circle" class="h-[50px] w-[50px]"></mat-icon>
      </div>
      <p class="font-medium text-xl text-text-primary">Set my password</p>
      <p class="font-medium text-base text-text-secondary text-center">Keep your account secure with a unique password
      </p>
    </div>

    <form [formGroup]="passwordForm" (keyup.enter)="save()" class="mt-8">
      <mat-form-field appearance="outline" class="h-14 w-full">
        <mat-label>New Password</mat-label>
        <input matInput formControlName="newPassword" [type]="showPassword ? 'text' : 'password'" autocomplete="off">
        <mat-icon matSuffix class="material-icons-outlined" (click)="showPassword=true"
          *ngIf="!showPassword">visibility</mat-icon>
        <mat-icon matSuffix class="material-icons-outlined" (click)="showPassword=false"
          *ngIf="showPassword">visibility_off</mat-icon>
      </mat-form-field>

      <div class="flex flex-col mt-2">
        <ng-container *ngFor="let key of objectKeys(validations); first as isFirst">
          <div class="flex flex-row items-center font-medium text-xs text-blue-grey-900 mt-1" *ngIf="!isFirst">
            <mat-icon class="material-symbols-outlined h-5 w-4 text-base"
              [ngClass]="validations[key].bool ? 'text-blue-900' : 'text-blue-grey-300'">check_circle</mat-icon>
            <p class="ml-2">{{validations[key].message}}</p>
          </div>
        </ng-container>
      </div>

      <mat-form-field appearance="outline" class="h-14 w-full mt-8">
        <mat-label>Confirm Password</mat-label>
        <input matInput formControlName="confirmPassword" [type]="showConfirmPassword ? 'text' : 'password'"
          onCopy="return false" onCut="return false" onDrag="return false" onpaste="return false" ondrop="return false"
          autocomplete="off">
        <mat-hint
          *ngIf="!validations['samePassword'].bool && passwordForm.controls['confirmPassword'].touched && !passwordForm.controls['confirmPassword'].errors"
          class="text-xs text-red-500">
          {{validations['samePassword'].message}}
        </mat-hint>
        <mat-hint
          *ngIf="passwordForm.controls['confirmPassword'].errors && (passwordForm.controls['confirmPassword'].dirty || passwordForm.controls['confirmPassword'].touched) && passwordForm.controls['confirmPassword'].errors['required']"
          class="text-red-500 text-xs">
          Required
        </mat-hint>
        <mat-icon matSuffix class="material-icons-outlined" (click)="showConfirmPassword=true"
          *ngIf="!showConfirmPassword">visibility</mat-icon>
        <mat-icon matSuffix class="material-icons-outlined" (click)="showConfirmPassword=false"
          *ngIf="showConfirmPassword">visibility_off</mat-icon>
      </mat-form-field>
    </form>

    <ng-container *ngIf="!saveFormLoading; else loading">
      <button class="text-base font-medium text-white rounded bg-blue-900 w-full h-12 mt-16"
        [disabled]="!checkValidations()" [ngClass]="checkValidations() ? 'bg-blue-900' : 'bg-blue-grey-700'"
        (click)="save()">Save Password</button>
    </ng-container>

  </div>


  <ng-template #loading>
    <button
      class="text-base font-medium text-white rounded bg-blue-900 w-full h-12 flex justify-center mt-16 items-center">
      <div class="loader" [ngStyle]="{'--loader-color': '#fff'}"></div>
    </button>
  </ng-template>
</section>