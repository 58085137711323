<div *ngIf="selectedCountry else loadingMobile" class="flex flex-col pt-12 pb-8 px-4 rounded bg-primary justify-between min-h-full grow">

  <div class="flex flex-col items-center gap-2">
    <div>
      <mat-icon *ngIf="isDarkEnable" svgIcon="ic_dark_quicko_logo_circle" class="h-[50px] w-[50px]"></mat-icon>
      <mat-icon *ngIf="!isDarkEnable" svgIcon="ic_quicko_logo_circle" class="h-[50px] w-[50px]"></mat-icon>
    </div>
    <p class="font-medium text-xl text-text-primary">Login into <span class="text-blue-900 font-semibold">Quicko</span>
    </p>
    <p class="font-medium text-base text-center text-text-secondary">Welcome Back!</p>
  </div>

  <form class="flex flex-col mt-8" [formGroup]="mobileForm" (keyup.enter)="save()">
    <mat-form-field appearance="outline" class="w-full">
      <img matPrefix src="{{selectedCountry.flag}}" class="h-5 w-7 ml-4 mr-3" />
      <mat-select [(value)]="selectedCountry" [ngClass]="{'dark':isDarkEnable}">
        <mat-option *ngFor="let c of countryList" [value]="c">
          <div class="flex flex-row">
            <img src="{{c.flag}}" class="h-5 w-7 mr-3" />
            <p>{{c.name}}</p>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full mt-3" floatLabel="always">
      <mat-label [ngClass]="{'text-text-primary':isDarkEnable}">Mobile Number</mat-label>
      <p matTextPrefix class="font-medium text-base text-text-primary mx-1" *ngIf="selectedCountry">
        +{{selectedCountry.numericCode}}</p>
      <input matInput formControlName="mobile" class="font-medium text-base text-text-primary" type="number">
      <mat-hint *ngIf="!isUser" class="font-medium text-xs text-alert-error-900">New here? Sign up
        today.</mat-hint>
      <mat-hint *ngIf="googleError" class="font-medium text-xs text-alert-error-900">Failed to signin. Try
        again</mat-hint>
      <mat-hint *ngIf="userError" class="font-medium text-xs text-alert-error-900">{{userError}}</mat-hint>
      <mat-error
        *ngIf="mobileForm.controls['mobile'].errors && (mobileForm.controls['mobile'].dirty || mobileForm.controls['mobile'].touched)"
        class="font-medium text-xs text-alert-error-900">
        <mat-hint *ngIf="mobileForm.controls['mobile'].errors['required']">Required</mat-hint>
      </mat-error>
    </mat-form-field>
  </form>

  <button (click)="onChangeLoginType()" class="font-medium text-xs text-blue-900 mt-3 self-start">Use Email
    Instead</button>

  <div class="flex flex-col gap-4 justify-center mt-14">
    <ng-container *ngIf="!saveFormLoading; else loading">
      <button #blueButton class="text-base font-medium text-white rounded bg-blue-900 w-full h-12"
        (click)="save()">Proceed</button>
    </ng-container>
    <ng-container>
      <asl-google-signin-button type='standard' size='large' [width]="googleButtonWidth" text="continue_with"
        logo_alignment="center"></asl-google-signin-button>
    </ng-container>
  </div>

  <p class="font-medium text-base text-text-secondary mt-6 text-center">Don't have an account? <a routerLink="../signup"
      class="text-blue-900 underline">Create account</a></p>

</div>



<ng-template #loading>
  <button class="text-base font-medium text-white rounded bg-blue-900 w-full h-12 flex justify-center items-center">
    <div class="loader" [ngStyle]="{'--loader-color': '#fff'}"></div>
  </button>
</ng-template>
<ng-template #blueLoading>
  <button
    class="flex text-base font-medium text-blue-900 border rounded border-blue-900 w-full h-12 justify-center items-center">
    <div class="loader" [ngStyle]="{'--loader-color': '#2962FF'}"></div>
  </button>
</ng-template>

<ng-template #loadingMobile>
  <div class="flex justify-center loading-height items-center my-auto mx-auto">
    <mat-spinner [diameter]="50" class="flex min-h-full"></mat-spinner>
  </div>
</ng-template>