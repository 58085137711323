<section id="email-login" class="flex flex-col items-center">

  <div class="flex flex-col rounded border-stroke bg-primary h-[640px] justify-center items-center">
    <div class="h-[571px] flex justify-center items-center px-4">
      <div class="flex flex-col items-center gap-2 px-4">
        <div>
          <mat-icon *ngIf="isDarkEnable" svgIcon="ic_dark_quicko_logo_circle" class="h-[50px] w-[50px]"></mat-icon>
          <mat-icon *ngIf="!isDarkEnable" svgIcon="ic_quicko_logo_circle" class="h-[50px] w-[50px]"></mat-icon>
        </div>
        <p class="font-medium text-xl text-center text-text-primary">Account Locked</p>
        <p class="font-medium text-base text-center text-text-secondary">This account is locked due to multiple failed login attempts.</p>
      </div>

    </div>
    <div class="py-6 bg-grey-bg-400 w-full">
      <p class="font-medium text-base text-center text-text-primary">Please retry in few minutes</p>
    </div>

  </div>



</section>