<div
  class="flex flex-col pt-12 pb-8 rounded bg-primary justify-between items-center max-h-[100vh] min-h-[100%] shrink-0">

  <div class="flex flex-col justify-center items-center gap-2 px-4 content-start">
    <div>
      <mat-icon *ngIf="isDarkEnable" svgIcon="ic_dark_quicko_logo_circle" class="h-[50px] w-[50px]"></mat-icon>
      <mat-icon *ngIf="!isDarkEnable" svgIcon="ic_quicko_logo_circle" class="h-[50px] w-[50px]"></mat-icon>
    </div>
    <p class="font-medium text-xl text-center text-text-primary">Thank you for using Quicko</p>
    <div class="flex flex-col">
      <p class="font-medium text-base text-center text-text-secondary">We hope to see you again soon!</p>
      <p class="font-semibold text-base text-center text-blue-900 ">{{email}}</p>
    </div>
  </div>

  <div class="flex flex-col gap-8 justify-center mt-[191px]">
    <div class="flex flex-col gap-4 justify-center px-4">
      <button class="text-base font-medium text-white rounded bg-blue-900 w-full h-12" routerLink="../login">Sign in
        again</button>
    </div>
    <div class="flex flex-col gap-4 border-t pt-8">
      <p class="font-medium text-base text-center text-text-primary px-4">Enjoyed our products? Give us a shout-out
        on our social media handles!</p>

      <div class="flex flex-row gap-4 justify-center px-4">

        <div class="w-11 h-11 bg-none border-[#E0E0E0] rounded-full border items-center flex justify-center">
          <a href="https://x.com/Quicko_official" target="_blank" rel="nofollow">
            <mat-icon svgIcon="ic_twitter" class="w-[20px] h-[20px] flex justify-center"></mat-icon>
          </a>
        </div>

        <div class="w-11 h-11 bg-none border-[#E0E0E0] rounded-full border items-center flex justify-center">
          <a href="https://in.linkedin.com/company/quicko-official" target="_blank" rel="nofollow">
            <mat-icon svgIcon="ic_linkedin" class="w-[20px] h-[20px] flex justify-center"></mat-icon>
          </a>
        </div>

        <div class="w-11 h-11 bg-none border-[#E0E0E0] rounded-full border items-center flex justify-center">
          <a href="https://www.instagram.com/quicko_official/" target="_blank" rel="nofollow">
            <mat-icon svgIcon="ic_instagram" class="w-[20px] h-[20px] flex justify-center"></mat-icon>
          </a>
        </div>
      </div>
    </div>
  </div>

</div>